import type {HTMLTemplateResult, TemplateResult, PropertyValues} from 'lit';
import {html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import {splitClassList} from '../../helpers/classMap.ts';

@customElement('element-button')
export class ElementButton extends LitElement {
  @property({type: String}) type:
    | 'primary'
    | 'secondary'
    | 'secondaryLight'
    | 'secondaryLightB2B' = 'primary';
  @property({type: Boolean}) disabled = false;
  @property({type: Boolean}) loading = false;
  @property({type: Object}) label: () =>
    | string
    | HTMLTemplateResult
    | TemplateResult
    | undefined;

  override updated(changedProps: PropertyValues) {
    if (changedProps.has('disabled'))
      this.style.pointerEvents = this.disabled ? 'none' : 'all';
  }

  override render() {
    const primary = this.type === 'primary';
    const secondaryLight = this.type === 'secondaryLight';
    const secondaryLightB2B = this.type === 'secondaryLightB2B';
    return html` <button
      type="button"
      class="body-links-and-text ${classMap(
        splitClassList({
          disabled: this.disabled,
          'bg-primary-gray': this.disabled,
          'text-white': primary || this.disabled,
          'bg-chm-red': primary && !this.disabled,
          'mouse:hover:bg-chm-focus-red':
            (primary || secondaryLightB2B) && !this.disabled,
          'text-chm-red':
            !primary && !this.disabled && (secondaryLight || secondaryLightB2B),
          'bg-white':
            !primary && !this.disabled && (secondaryLight || secondaryLightB2B),
          'mouse:hover:bg-page-main':
            !primary && !this.disabled && secondaryLight,
          'mouse:hover:text-chm-focus-red': !primary && !this.disabled,
          'group mouse:hover:text-white transition-colors duration-300':
            secondaryLightB2B && !this.disabled,
          'focus:bg-layers-popup': !primary && !this.disabled && secondaryLight,
          'focus:text-chm-focus-red':
            !primary && !this.disabled && secondaryLight,
          'bg-chm-light-gray': !primary && !secondaryLight,
          'text-chm-gray': !primary && !secondaryLight,
        }),
      )} flex h-logo-s select-none items-center rounded-lg px-[16px] py-[12px] font-bold active:bg-black active:!text-white md:h-logo-m md:px-[24px] md:py-[17px]"
    >
      ${this.loading
        ? html` <div
            class="${classMap({
              'bg-loader-animated': !primary,
              'bg-loader-animated-white': primary,
            })} mb-[45px] mt-auto h-[24px] w-[24px] self-center bg-no-repeat md:mb-[76px] mdxx:mb-[80px]"
          ></div>`
        : this.label()}
    </button>`;
  }

  override createRenderRoot() {
    return this;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'element-button': ElementButton;
  }
}
