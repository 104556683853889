/**
 * This file is for the Google analytics tracking.
 */

import {pairwise, startWith} from 'rxjs';
import {GA_MEASUREMENT_ID} from './envs.ts';
import type {USER_ROLE} from './helpers/helper-users.ts';
import {userStatus$} from './stores/store-main.ts';

function loadGA(id: string) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  document.head.insertBefore(script, document.head.firstChild);

  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
}

export function initializeAnalytics() {
  const search = new URLSearchParams(document.location.search);
  if (GA_MEASUREMENT_ID && !search.has('noga')) {
    loadGA(GA_MEASUREMENT_ID);
    sendAnalytics('js', new Date());
    sendAnalytics('config', GA_MEASUREMENT_ID, {
      anonymize_ip: true,
      send_page_view: false,
    });
  }
  userStatus$
    .pipe(startWith(undefined), pairwise())
    .subscribe(([oldStatus, newStatus]: [USER_ROLE, USER_ROLE]) => {
      if (oldStatus === newStatus) {
        return;
      }
      sendAnalytics('config', GA_MEASUREMENT_ID, {
        user_properties: {
          subscription_status: newStatus,
        },
      });
    });
}

export function sendAnalytics(_command: string, ..._args: any[]) {
  // @ts-ignore
  if (window.dataLayer) {
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  }
}
