export interface ClassMapObject {
  [key: string]: boolean;
}

// Splits a space-separated class list to prepare the input for classMap directive
// with the goal of adding or removing several classes based on the same boolean value
export function splitClassList(classLists: ClassMapObject) {
  const normalizedClassList: ClassMapObject = {};
  for (const classes in classLists) {
    const classesCondition = classLists[classes];
    classes.split(' ').forEach((classname) => {
      if (classname) normalizedClassList[classname] = classesCondition;
    });
  }
  return normalizedClassList;
}
