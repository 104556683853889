import {html} from 'lit';
import {msg} from '@lit/localize';
import type {
  AdditionalRouteLayerCode,
  LandCode,
  LayerInfo,
  POILayerInfo,
  RouteCategoryCode,
  RouteLayerInfo,
  MultiLandLayers,
} from './layerTypes.ts';

export const landCategoryUrlParams = [
  'wanderlandetappennational',
  'wanderlandetappenregional',
  'wanderlandetappenlokal',
  'wanderlandetappenhandicap',
  'wanderwegnetz',
  'velolandetappennational',
  'velolandetappenregional',
  'velolandetappenlokal',
  'mtblandetappennational',
  'mtblandetappenregional',
  'mtblandetappenlokal',
  'skatinglandetappennational',
  'skatinglandetappenregional',
  'skatinglandetappenlokal',
  'kanulandetappennational',
  'kanulandetappenregional',
  'crosscountryetappenlokal',
  'crosscountrynetwork',
] as const;

export const LAND_CATEGORIES: Record<MultiLandLayers, string[]> = {
  wanderland: [
    'WanderlandEtappenNational',
    'WanderlandEtappenRegional',
    'WanderlandEtappenLokal',
    'WanderlandEtappenHandicap',
    'Wanderwegnetz',
  ],
  veloland: [
    'VelolandEtappenNational',
    'VelolandEtappenRegional',
    'VelolandEtappenLokal',
  ],
  mtbland: [
    'MtblandEtappenNational',
    'MtblandEtappenRegional',
    'MtblandEtappenLokal',
  ],
  skatingland: [
    'SkatinglandEtappenNational',
    'SkatinglandEtappenRegional',
    'SkatinglandEtappenLokal',
  ],
  kanuland: ['KanulandEtappenNational', 'KanulandEtappenRegional'],
  crosscountry: ['CrosscountryEtappenLokal', 'CrosscountryNetwork'],
};

export const summerLandLayers = [
  'wanderland',
  'veloland',
  'mtbland',
  'skatingland',
  'kanuland',
] as const;
export const winterLandLayers = [
  'winterhiking',
  'snowshoe',
  'crosscountry',
  'sledging',
] as const;
export const multiLandLayers = [...summerLandLayers, 'crosscountry'] as const;

export const summerLandCodes = [
  'hike',
  'cycle',
  'mountainbike',
  'skate',
  'canoe',
] as const;
export const winterLandCodes = [
  'crosscountry',
  'winterhike',
  'snowshoe',
  'sledge',
] as const;

export const alternativeLandCodes = ['mountainbikeland', 'mtb'] as const;
export const legacyLandCodes = [
  'wander',
  'velo',
  'mountainbike',
  'skating',
  'kanu',
  'winterhiking',
  'snowshoe',
  'crosscountry',
  'sledging',
] as const;

export const POILayersInfo: POILayerInfo[] = [
  {
    title: () => msg('Public transport'),
    icon: 'bg-[url("./src/img/i_transport.svg")]',
    winter: true,
    summer: true,
    code: 'station',
  },
  {
    title: () => msg('Accommodation'),
    icon: 'bg-[url("./src/img/i_accommodation.svg")]',
    winter: true,
    summer: true,
    code: 'accommodation',
  },
  {
    title: () => msg('Places'),
    icon: 'bg-[url("./src/img/i_places.svg")]',
    winter: true,
    summer: true,
    code: 'place',
  },
  {
    title: () => msg('Places of interest'),
    icon: 'bg-[url("./src/img/i_sightseeing.svg")]',
    winter: true,
    summer: true,
    code: 'sightseeing',
  },
  {
    title: () => msg('Bicycle and e-bike renting'),
    icon: 'bg-[url("./src/img/i_bicycle.svg")]',
    winter: false,
    summer: true,
    code: 'velorental',
  },
  {
    title: () => msg('Cycle service'),
    icon: 'bg-[url("./src/img/i_service.svg")]',
    winter: false,
    summer: true,
    code: 'cycleservice',
  },
  {
    title: () => msg('Swiss Parks'),
    icon: 'bg-[url("./src/img/i_swissparks.svg")]',
    winter: true,
    summer: true,
    code: 'swisspark',
  },
  {
    title: () => msg('Livestock guardian dogs'),
    icon: 'bg-[url("./src/img/i_alpguarddogs.svg")]',
    winter: false,
    summer: true,
    code: 'alpguarddog',
  },
  {
    title: () => msg('Designated wildlife areas', {id: 'wildlife-layer-group'}),
    icon: 'bg-[url("./src/img/i_wildlife.svg")]',
    winter: true,
    summer: false,
    code: 'wildlife',
  },
  {
    title: () => msg('Slope classes over 30°'),
    icon: 'bg-[url("./src/img/i_slope.svg")]',
    winter: true,
    summer: true,
    code: 'slope',
  },
];

export const HiddenLayersInfo: POILayerInfo[] = [
  {title: () => msg('slowUp'), winter: true, summer: true, code: 'slowup'},
  {
    title: () => msg('Municipalities'),
    winter: true,
    summer: true,
    code: 'municipalities',
  },
  {title: () => msg('Cantons'), winter: true, summer: true, code: 'cantons'},
];

export const baseLayerCodes = ['pk', 'pkg', 'lb'] as const;
export type BaseLayerCode = (typeof baseLayerCodes)[number];

export const BackgroundLayersInfo: LayerInfo<BaseLayerCode>[] = [
  {
    title: () => msg('Map in colour'),
    icon: 'bg-[url("./src/img/i_map_color.svg")]',
    code: 'pk',
  },
  {
    title: () => msg('Map grey'),
    icon: 'bg-[url("./src/img/i_map_grey.svg")]',
    code: 'pkg',
  },
  {
    title: () => msg('Aerial view'),
    icon: 'bg-[url("./src/img/i_map_aerial.svg")]',
    code: 'lb',
  },
];

export const AdditionalRouteLayersInfo: LayerInfo<AdditionalRouteLayerCode>[] =
  [
    {
      title: () => msg('Route-Numbers'),
      icon: 'bg-[url("./src/img/i_route_nr.svg")]',
      code: 'logos',
    },
    {
      title: () => msg('Photos'),
      icon: 'bg-[url("./src/img/i_photos.svg")]',
      code: 'photos',
    },
    {
      title: () => msg('Closure / diversion'),
      icon: 'bg-[url("./src/img/i_detour.svg")]',
      codes: ['detours', 'shooting'],
    },
  ];

/**
 * Internal codes for route categories
 */
export const routeCategoryCodes = [
  'national',
  'regional',
  'local',
  'handicap',
] as const;

const winterCategories: RouteCategoryCode[] = ['local'];
const nationalRegional: RouteCategoryCode[] = ['national', 'regional'];
const nationalRegionalLocal: RouteCategoryCode[] = [
  ...nationalRegional,
  'local',
];

/**
 * Describe route layers
 * Keep keys same as urlParams.lands
 */
export const ROUTE_LAYERS: Record<LandCode, RouteLayerInfo> = {
  hike: {
    legacyLandCode: 'wander',
    logoFilename: 'logos_wander_segment.geojson',
    season: 'summer',
    colorClass: 'bg-hike',
    pageColorClass: 'bg-page-hike',
    logoClass: 'bg-[url("./src/img/logo_wl.svg")]',
    landCode: 'hike',
    layer: 'wanderland',
    getTitle: () =>
      msg(html`Hiking<br />in Switzer&#8203;land`, {id: 'hike-title-hyphen'}),
    getTitleNoHyphen: () =>
      msg(html`Hiking<br />in Switzer&#8203;land`, {id: 'hike-title'}),
    getFlatTitle: () => msg('Hiking in Switzerland'),
    getLayerHintTitle: () => msg('Hiking in Switzerland routes'),
    categories: [...nationalRegionalLocal, 'handicap'],
    colorRGB: '#609E26',
  },
  cycle: {
    legacyLandCode: 'velo',
    logoFilename: 'logos_velo_segment.geojson',
    season: 'summer',
    colorClass: 'bg-cycle',
    pageColorClass: 'bg-page-cycle',
    logoClass: 'bg-[url("./src/img/logo_vl.svg")]',
    landCode: 'cycle',
    layer: 'veloland',
    getTitle: () =>
      msg(html`Cycling<br />in Switzer&#8203;land`, {id: 'cycle-title-hyphen'}),
    getTitleNoHyphen: () =>
      msg(html`Cycling<br />in Switzer&#8203;land`, {id: 'cycle-title'}),
    getFlatTitle: () => msg('Cycling in Switzerland'),
    getLayerHintTitle: () => msg('Cycling in Switzerland routes'),
    categories: nationalRegionalLocal,
    colorRGB: '#5C94CF',
  },
  mountainbike: {
    legacyLandCode: 'mountainbike',
    logoFilename: 'logos_mountainbike_segment.geojson',
    season: 'summer',
    colorClass: 'bg-mountainbike',
    pageColorClass: 'bg-page-mountainbike',
    logoClass: 'bg-[url("./src/img/logo_ml.svg")]',
    landCode: 'mountainbike',
    layer: 'mtbland',
    getTitle: () =>
      msg(html`Mountain&#8203;biking<br />in Switzer&#8203;land`, {
        id: 'mountainbike-title-hyphen',
      }),
    getTitleNoHyphen: () =>
      msg(html`Mountain&#8203;biking<br />in Switzer&#8203;land`, {
        id: 'mountainbike-title',
      }),
    getFlatTitle: () => msg('Mountainbiking in Switzerland'),
    getLayerHintTitle: () => msg('Mountainbiking in Switzerland routes'),
    categories: nationalRegionalLocal,
    colorRGB: '#DEAB18',
  },
  skate: {
    legacyLandCode: 'skating',
    logoFilename: 'logos_skating_segment.geojson',
    season: 'summer',
    colorClass: 'bg-skate',
    pageColorClass: 'bg-page-skate',
    logoClass: 'bg-[url("./src/img/logo_sl.svg")]',
    landCode: 'skate',
    layer: 'skatingland',
    getTitle: () =>
      msg(html`Skating<br />in Switzer&#8203;land`, {id: 'skate-title-hyphen'}),
    getTitleNoHyphen: () =>
      msg(html`Skating<br />in Switzer&#8203;land`, {id: 'skate-title'}),
    getFlatTitle: () => msg('Skating in Switzerland'),
    getLayerHintTitle: () => msg('Skating in Switzerland routes'),
    categories: nationalRegionalLocal,
    colorRGB: '#9D7FB7',
  },
  canoe: {
    legacyLandCode: 'kanu',
    logoFilename: 'logos_kanu_segment.geojson',
    season: 'summer',
    colorClass: 'bg-canoe',
    pageColorClass: 'bg-page-canoe',
    logoClass: 'bg-[url("./src/img/logo_kl.svg")]',
    landCode: 'canoe',
    layer: 'kanuland',
    getTitle: () =>
      msg(html`Canoeing<br />in Switzer&#8203;land`, {
        id: 'canoe-title-hyphen',
      }),
    getTitleNoHyphen: () =>
      msg(html`Canoeing<br />in Switzer&#8203;land`, {
        id: 'canoe-title',
      }),
    getFlatTitle: () => msg('Canoeing in Switzerland'),
    getLayerHintTitle: () => msg('Canoeing in Switzerland routes'),
    categories: nationalRegional,
    colorRGB: '#409698',
  },
  winterhike: {
    legacyLandCode: 'winterhiking',
    logoFilename: 'logos_winterhiking_segment.geojson',
    season: 'winter',
    colorClass: 'bg-winterhike',
    pageColorClass: 'bg-page-winterhike',
    logoClass: 'bg-[url("./src/img/logo_wi_h.svg")]',
    landCode: 'winterhike',
    layer: 'winterhiking',
    getTitle: () =>
      msg(html`Winter hiking<br />`, {id: 'winterhike-title-hyphen'}),
    getTitleNoHyphen: () => msg('Winter hiking', {id: 'winterhike-title'}),
    getFlatTitle: () => msg('Winter hiking'),
    getLayerHintTitle: () => msg('Winter hiking paths'),
    categories: winterCategories,
    colorRGB: '#EB6EA6',
  },
  snowshoe: {
    legacyLandCode: 'snowshoe',
    logoFilename: 'logos_snowshoe_segment.geojson',
    season: 'winter',
    colorClass: 'bg-snowshoe',
    pageColorClass: 'bg-page-snowshoe',
    logoClass: 'bg-[url("./src/img/logo_wi_s.svg")]',
    landCode: 'snowshoe',
    layer: 'snowshoe',
    getTitle: () =>
      msg(html`Snow&#8203;shoe trekking<br />`, {id: 'snowshoe-title-hyphen'}),
    getTitleNoHyphen: () =>
      msg(html`Snow&#8203;shoe trekking`, {id: 'snowshoe-title'}),
    getFlatTitle: () => msg('Snowshoe trekking'),
    getLayerHintTitle: () => msg('Snowshoe trekking trails'),
    categories: winterCategories,
    colorRGB: '#BC5D8F',
  },
  crosscountry: {
    legacyLandCode: 'crosscountry',
    logoFilename: 'logos_crosscountry_segment.geojson',
    season: 'winter',
    colorClass: 'bg-crosscountry',
    pageColorClass: 'bg-page-crosscountry',
    logoClass: 'bg-[url("./src/img/logo_wi_c.svg")]',
    landCode: 'crosscountry',
    layer: 'crosscountry',
    getTitle: () =>
      msg('Cross-country skiing', {id: 'crosscountry-title-hyphen'}),
    getTitleNoHyphen: () =>
      msg('Cross-country skiing', {id: 'crosscountry-title'}),
    getFlatTitle: () => msg('Cross-country skiing'),
    getLayerHintTitle: () => msg('Cross-country skiing trails'),
    categories: winterCategories,
    colorRGB: '#02A7DE',
  },
  sledge: {
    legacyLandCode: 'sledging',
    logoFilename: 'logos_sledging_segment.geojson',
    season: 'winter',
    colorClass: 'bg-sledge',
    pageColorClass: 'bg-page-sledge',
    logoClass: 'bg-[url("./src/img/logo_wi_sl.svg")]',
    landCode: 'sledge',
    layer: 'sledging',
    getTitle: () => msg('Sledging', {id: 'sledge-title-hyphen'}),
    getTitleNoHyphen: () => msg('Sledging', {id: 'sledge-title'}),
    getFlatTitle: () => msg('Sledging'),
    getLayerHintTitle: () => msg('Sledging runs'),
    categories: winterCategories,
    colorRGB: '#9A6850',
  },
};

export const STATION_CODES = [
  'alpinetaxi',
  'funicular',
  'cableway',
  'telecabin',
  'chairlift',
  'boat',
  'bus',
  'trambus',
  'train',
] as const;

export const ACCOMMODATION_CODES = [
  'hotel',
  'bedbreakfast',
  'youthhostel',
  'backpacker',
  'groupaccom',
  'sleepingstraw',
  'farmaccom',
  'vacationapptmt',
  'mountainhut',
  'campingsite',
] as const;

export const SERVICE_CODES = [
  'place',
  'sightseeing',
  'velorental',
  'cycleservice',
] as const;

export const ADDITIONAL_LAYERTREE_CODES = [
  ...STATION_CODES,
  ...ACCOMMODATION_CODES,
  ...SERVICE_CODES,
  'wildlife',
  'alpguarddog',
  'slope',
  'swisspark',
  'accommodation',
  'station',
  'slowup',
  'detour',
  'trackLayer',
  'municipalities',
  'cantons',
  'shooting',
] as const;

export const SEASON_CODES = ['winter', 'summer'] as const;

export const WS_ALIASES = {
  accomodation: 'accommodation',
  cycleservice: 'cycleservice',
  places: 'place',
  poi: 'sightseeing',
  rental: 'velorental',
} as const;

export const LAND_LAYER_ORDER = [
  'hike.national',
  'hike.regional',
  'hike.local',
  'hike.handicap',
  'hike',
  'velo.national',
  'velo.regional',
  'velo.local',
  'velo',
  'mountainbike.national',
  'mountainbike.regional',
  'mountainbike.local',
  'mountainbike',
  'skate.national',
  'skate.regional',
  'skate.local',
  'skate',
  'canoe.national',
  'canoe.regional',
  'canoe.local',
  'canoe',
  'crosscountry.local',
  'crosscountry',
  'winterhike',
  'snowshoe',
  'sledge',
] as const;

export const LAYER_ORDER = [
  'slowup',

  // stations
  'station',
  ...STATION_CODES,

  // accomodation
  'accommodation',
  ...ACCOMMODATION_CODES,

  // service
  'services',
  ...SERVICE_CODES,

  'detour',
  'shooting',

  // land layers
  ...LAND_LAYER_ORDER,

  'municipalities',
  'cantons',

  'slope',

  'swisspark',
  'alpguarddog',

  'wildlife',
  'wildlifearea',
  'wildlifereserve',
  'tour',
] as const;

export const ADDITIONAL_LAYER_TYPES = [
  'accommodation',
  'velorental',
  'cycleservice',
  'bookable',
  'alpinetaxi',
  'station',
  'swisspark',
  'slowup',
] as const;

export const REGIONS = [
  {title: () => msg('All'), value: 0, default: true},
  {title: () => msg('Aargau Region'), value: 14},
  {title: () => msg('Bern Region'), value: 6},
  {title: () => msg('Fribourg Region'), value: 13},
  {title: () => msg('Zurich Region'), value: 3},
  {title: () => msg(html`Jura & Three-Lakes`), value: 8},
  {title: () => msg('Geneva Region'), value: 10},
  {title: () => msg('Lucerne - Lake Lucerne Region'), value: 4},
  {title: () => msg('Eastern Switzerland / Liechtenstein'), value: 2},
  {title: () => msg('Lake Geneva Region'), value: 9},
  {title: () => msg('Basel Region'), value: 5},
  {title: () => msg('Graubünden'), value: 1},
  {title: () => msg('Valais'), value: 11},
  {title: () => msg('Ticino'), value: 12},
];

export const CLUSTERED_LAYER_CODES = [
  ...STATION_CODES,
  ...ACCOMMODATION_CODES,
  ...SERVICE_CODES,
] as const;

export const OVERLAY_LAYER_CODES: (
  | (typeof CLUSTERED_LAYER_CODES)[number]
  | 'alpguarddog'
  | 'slope'
  | 'wanderwegnetz'
  | 'wildlife'
  | 'slowup'
  | 'swisspark'
  | 'crosscountrynetwork'
  | 'municipalities'
  | 'cantons'
)[] = [
  ...CLUSTERED_LAYER_CODES,
  'alpguarddog',
  'slope',
  'wanderwegnetz',
  'wildlife',
  'slowup',
  'swisspark',
  'crosscountrynetwork',
  'municipalities',
  'cantons',
];
