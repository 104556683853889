/**
 * This file is for creation of urls.
 */

import {normalizeB2bUrl, normalizeURL} from './urlNormalizer.ts';
import type {
  LandCode,
  RouteCategoryCode,
  SeasonCode,
} from '../layers/layerTypes.ts';
import type {B2bLanguageCode, LanguageCode} from '../i18n.ts';
import type {Teaser} from '../apis/api-teasers.ts';
import {replaceAll} from '../helpers/string.ts';
import {resolveDeployPrefix} from '../initialization/deployPrefixResolver.ts';
import {season$, stpShortTitles$} from '../stores/store-main.ts';
import {
  currentlyActiveTourType$,
  activeTrackId$,
} from '../stores/store-tours.ts';
import {SWISS_EXTENT} from '../helpers/constants.ts';
import {msg} from '@lit/localize';
import {landCodeToApiCode} from '../layers/layerHelpers.ts';
import {BASE_API_URL, GRAPHHOPPER_URL} from '../envs.ts';

import {
  legendUrlFragment,
  slowupLegendUrlFragment,
  landUrlFragment,
  routeCategoriesUrlFragment,
  seasonsUrlFragment,
  mapUrlFragment,
  routeUrlFragment,
  segmentUrlFragment,
  schmPlusInstructionsUrlFragment,
} from './urlParams.ts';
import {
  getLayersForLayertree,
  getPersistentSearchParams,
} from './urlSearchParams.ts';
import type {TourType} from '../apis/api-tours.ts';

const deployPrefix = resolveDeployPrefix();

/**
 * @param lang
 * @return URL like /en/legend
 */
export function createLegendURL(lang: LanguageCode): string {
  return `${deployPrefix}/${lang}/${legendUrlFragment[lang]}`;
}

/**
 * @param lang
 * @return URL like /en/slowup-legend
 */
export function createSlowupLegendURL(lang: LanguageCode): string {
  return `${deployPrefix}/${lang}/${slowupLegendUrlFragment[lang]}`;
}

/**
 * @param lang
 * @param land
 * @param [category] optional category
 * @return URL like /en/hiking-in-switzerland or /en/hiking-in-switzerland/national
 */
export function createLandURL(
  lang: LanguageCode,
  land: LandCode,
  category?: RouteCategoryCode,
): string {
  const landPath = `${deployPrefix}/${lang}/${landUrlFragment[land][lang]}`;
  if (category) {
    return `${landPath}/${routeCategoriesUrlFragment[category][lang]}`;
  }
  return landPath;
}

export function createMainURL(lang: LanguageCode): string {
  return `${deployPrefix}/${lang}`;
}

/**
 * @param lang
 * @param season
 * @return URL like /fr/ete
 */
export function createSeasonURL(
  lang: LanguageCode,
  season: SeasonCode,
): string {
  return `${deployPrefix}/${lang}/${seasonsUrlFragment[season][lang]}`;
}

export function createMapURL(
  lang: LanguageCode,
  season?: SeasonCode,
  land?: LandCode,
  resetPosition?: boolean,
): string {
  let mapUrl = `${deployPrefix}/${lang}/${mapUrlFragment[lang]}`;
  if (!season) return mapUrl;
  mapUrl = `${mapUrl}?season=${season}`;
  if (land) mapUrl = `${mapUrl}&layers=${landCodeToApiCode[land]}`;
  else
    mapUrl = `${mapUrl}&layers=${
      season === 'summer' ? 'wanderland' : 'winterhiking'
    }`;
  if (resetPosition)
    mapUrl = `${mapUrl}&fitBbox=true&bbox=${SWISS_EXTENT.join(
      '-',
    )}&bboxPadding=10-10-10-10`;
  return mapUrl;
}

export function createSightseeingPlaceUrl(
  title: string,
  lang: LanguageCode,
  layer: string,
  id: number,
) {
  return `${deployPrefix}/${lang}/${layer}-${id}-${replaceAll(
    title.toLowerCase(),
    ' ',
    '-',
  )}`;
}

export function createPointOfInterestUrl(
  lang: LanguageCode,
  layer: string,
  id: number,
) {
  return psp(`${deployPrefix}/${lang}/${layer}-${id}`);
}

export function getUrlForLang(
  lang: LanguageCode,
  detectedLang: LanguageCode,
  season: SeasonCode,
) {
  const path = location.pathname.replace(detectedLang, lang);
  const deployPrefix = resolveDeployPrefix();
  const stpTitles = stpShortTitles$.getValue();
  if (stpTitles) {
    return `${location.origin}/${lang}/${stpTitles[lang]}${location.search}${location.hash}`;
  }
  const {normalizedURL} = normalizeURL(
    `${location.origin}/${path}/${location.search}`,
    lang,
    season,
    deployPrefix,
  );
  return normalizedURL;
}

export function createStaticPageUrl(lang: LanguageCode, titleShort: string) {
  return `${deployPrefix}/${lang}/${titleShort}`;
}

// create a B2C URL for a static page with the correct environment:
export function createStaticPageUrlB2C(lang: LanguageCode, titleShort: string) {
  let prefix = undefined;
  const loc = document?.location;
  if (loc && loc.hostname.endsWith('.info')) {
    prefix = `${loc.protocol}//${loc.hostname.replace('.info', '.ch')}`;
  }
  return [prefix, deployPrefix, lang, titleShort]
    .filter((e) => e !== undefined)
    .join('/');
}

export function createUrlFromTeaser(
  lang: LanguageCode,
  t: Teaser,
  season: SeasonCode,
  land: LandCode,
): [string, string] {
  const text = t.urlText
    ? t.urlText
    : msg('Learn more', {desc: 'default link text'});
  const titleShort = t.staticPage?.titleShort || t.topicPage?.titleShort;
  if (titleShort) {
    const defaultStaticPageTitle = t.defaultStaticPage?.titleShort
      ? `#${t.defaultStaticPage?.titleShort}`
      : '';
    const url = `${deployPrefix}/${lang}/${titleShort}?origin=${
      land || season
    }${defaultStaticPageTitle}`;
    return [url, text];
  }
  if (t.urlLink) {
    const link = t.urlLink.startsWith('/')
      ? `${deployPrefix}${t.urlLink}`
      : t.urlLink;
    return [link, text];
  }
  return ['', ''];
}

export function createRouteURL(
  lang: LanguageCode,
  land: LandCode,
  routeNb: number,
) {
  return `${createLandURL(lang, land)}/${routeUrlFragment[lang]}-${routeNb}`;
}

export function createSegmentURL(
  lang: LanguageCode,
  land: LandCode,
  routeNb: number,
  segmentNb: number,
) {
  return `${createRouteURL(lang, land, routeNb)}/${
    segmentUrlFragment[lang]
  }-${segmentNb}`;
}

export function createMapUrlFromBbox(
  lang: LanguageCode,
  params: URLSearchParams,
  bbox: number[],
  keepLayersFromStore = false,
  highlightPointCoordinates?: [number, number],
): string {
  const {bgLayer, detours, photos, logos} = Object.fromEntries(params);
  const newParams = new URLSearchParams();
  newParams.set('bbox', bbox.join('-'));
  newParams.set('fitBbox', 'true');
  newParams.set('bgLayer', bgLayer);
  newParams.set('detours', detours);
  newParams.set('layers', getLayersForLayertree(params).join(','));
  newParams.set('photos', photos);
  newParams.set('logos', logos);
  newParams.set('season', season$.getValue());
  newParams.set('keepLayers', String(keepLayersFromStore));
  newParams.set(
    'highlightPointCoordinates',
    String(highlightPointCoordinates.join('-')),
  );
  return psp(`${deployPrefix}/${lang}/map?${newParams.toString()}`, '&');
}

export function createToursURL(lang: LanguageCode) {
  return psp(`${deployPrefix}/${lang}/tours`);
}

export function createOldUILoginURLWithTour(lang: LanguageCode) {
  const tourType =
    currentlyActiveTourType$.getValue() === 'normal'
      ? 'trackId'
      : 'recordedTrackId';
  const tourId = activeTrackId$.getValue();
  return createOldUILoginURL(lang) + `&${tourType}=${tourId}`;
}

export function createOldUILoginURL(lang: LanguageCode) {
  return `https://map.schweizmobil.ch/?lang=${lang}&showLogin`;
}

/**
 * Add persistent search parameters to the provided URL.
 * @param url The url to decorate
 * @param sep The separator: ? or &
 * @return the updated URL
 */
function psp(url: string, sep = '?'): string {
  const p = getPersistentSearchParams().toString();
  if (p) {
    return `${url}${sep}${p}`;
  }
  return url;
}

export function createTourURL(id: number, type: TourType, lang: LanguageCode) {
  return psp(
    `${deployPrefix}/${lang}/${
      type === 'normal' ? 'tour' : 'recordedtrack'
    }/${id}`,
  );
}

export function createNewTourURL(lang: LanguageCode) {
  return psp(`${deployPrefix}/${lang}/tour`);
}

export function createLoginPageURL(lang: LanguageCode) {
  return psp(`${deployPrefix}/${lang}/login`);
}

export function createGraphhopperRouterURL(profile: string) {
  return `${GRAPHHOPPER_URL}/route?profile=${profile}&type=json&elevation=true&way_point_max_distance=0&instructions=false&points_encoded=true`;
}

export function createGraphhopperSnapperURL() {
  return `${GRAPHHOPPER_URL}/nearest?elevation=true`;
}

export function createAddImageUrl(): string {
  return `${BASE_API_URL}/api/6/pois/addimage`;
}

export function createSchmPlusInstructionsUrl(lang: LanguageCode): string {
  return `${deployPrefix}/${lang}/${schmPlusInstructionsUrlFragment[lang]}`;
}

export function getB2bUrlForLang(
  lang: B2bLanguageCode,
  detectedLang: LanguageCode,
) {
  const path = location.pathname.replace(detectedLang, lang);
  const deployPrefix = resolveDeployPrefix();
  const stpTitles = stpShortTitles$.getValue();
  if (stpTitles) {
    return `${location.origin}/${lang}/${stpTitles[lang]}${location.search}${location.hash}`;
  }
  const normalizedURL = normalizeB2bUrl(
    `${location.origin}/${path}${location.search}`,
    deployPrefix,
    lang,
  );
  return normalizedURL;
}
