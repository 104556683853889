import {BASE_API_URL} from '../envs.ts';

import {
  authFetch,
  type TourColorName,
  type TourTimeType,
} from '../apis/api-tours.ts';

interface OdooUserDetails {
  firstName: string;
}

export interface UserSettings {
  color: TourColorName;
  filterId: number;
  timetype: TourTimeType;
  public: boolean;
  opacity: number;
  bicycleSpeed: number;
  hikeSpeed: number;
  winterhikeSpeed: number;
  snowshoeSpeed: number;
  mountainBikingSpeed: number;
  gpsPoints: number;
}

export interface User {
  userid?: number;
  email: string;
  firstName?: string;
}

export interface UserCheck extends User {
  loggedin: boolean;
}

export interface UserLogin extends User {
  loginErrorCode: number;
  loginErrorMsg: string;
}

export async function postCookieLogin(
  username: string,
  password: string,
): Promise<UserLogin> {
  const response = await fetch(`${BASE_API_URL}/api/4/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  });
  const userData = await response.json();
  return {
    userid: userData.userid,
    email: userData.name,
    firstName: userData.name,
    loginErrorCode: userData.loginErrorCode,
    loginErrorMsg: userData.loginErrorMsg,
  };
}

export async function postCookieLogout() {
  await fetch(`${BASE_API_URL}/api/4/logout`, {
    credentials: 'include',
    method: 'post',
  });
}

export async function fetchUserCheck(): Promise<UserCheck> {
  const response = await fetch(`${BASE_API_URL}/api/4/check`, {
    credentials: 'include',
  });
  return response.json();
}

export async function fetchUserSettings(): Promise<UserSettings> {
  const settings = await authFetch(`${BASE_API_URL}/api/4/usersettings`, true);
  const userSettings = await settings.json();
  return {
    color: userSettings.color,
    filterId: userSettings.filterid,
    timetype: userSettings.timetype,
    public: userSettings.public,
    opacity: userSettings.opacity,
    bicycleSpeed: userSettings.bicycle_speed,
    hikeSpeed: userSettings.hike_speed,
    winterhikeSpeed: userSettings.winterhike_speed,
    snowshoeSpeed: userSettings.snowshoe_speed,
    mountainBikingSpeed: userSettings.mountainBikingSpeed,
    gpsPoints: userSettings.gps_points,
  };
}

export async function fetchUserDetails(): Promise<OdooUserDetails> {
  try {
    const response = await authFetch(`${BASE_API_URL}/api/4/whoami`, true);
    if (response.ok) {
      return response.json();
    }
  } catch {
    // fallback to error case
  }
  return undefined;
}
