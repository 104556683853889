/**
 * The application only accepts the newest up-to-date format of URLs.
 * The code in this file will accept all the legacy possibilities.
 * Except for the normalizeURL function, all content in this file is private.
 */

import {
  copyrightUrlFragment,
  detoursUrlFragment,
  feedbackUrlFragment,
  getAllParts1,
  getCodeForAnyLang,
  getIntForAnyPartFragment,
  getRouteAndSegmentNumbers,
  landUrlFragment,
  legacyCopyrightUrlFragment,
  legacyDisclaimerUrlFragment,
  legacyFeedbackUrlFragment,
  legendUrlFragment,
  mapUrlFragment,
  oldOverlayDirectUrlFragment,
  overlayDirectUrlFragment,
  publishingCreditsUrlFragment,
  routeCategoriesUrlFragment,
  routeUrlFragment,
  seasonsUrlFragment,
  segmentUrlFragment,
  slowupLegendUrlFragment,
  legacyMediaUrlFragment,
  mediaUrlFragment,
  legacyChmServiceUrlFragment,
  chmServiceUrlFragment,
  legacyMobileUrlFragment,
  mobileUrlFragment,
  copyrightPhotoUrlFragment,
  toursUrlFragment,
  tourUrlFragment,
  recordedTourUrlFragment,
} from './urlParams.ts';
import {ROUTE_LAYERS} from '../layers/layerConstants.ts';
import type {BaseLayerCode} from '../layers/layerConstants.ts';
import type {
  LandCode,
  POICategory,
  RouteCategoryCode,
  SeasonCode,
} from '../layers/layerTypes.ts';
import type {LanguageCode, B2bLanguageCode} from '../i18n.ts';
import {languageCodes} from '../i18n.ts';
import {selectedRoutes, selectedRoutesFilter} from './urlSelectedRoutes.ts';
import {
  apiCodeToLandCode,
  isLandLayer,
  isSeasonCode,
  isLegacyUserLand,
  isAlternativeLandCode,
  landToLandCode,
  alternativeCodeToLandCode,
  isBgCode,
} from '../layers/layerHelpers.ts';

/**
 * The symbol used when the URL is canonical.
 */
export const CANONICAL = Symbol('canonical');
export const PARTIAL = Symbol('partial');
export const FAILURE = Symbol('failure');
export type NormalizationCode =
  | typeof CANONICAL
  | typeof PARTIAL
  | typeof FAILURE;

export type OverlayType = POICategory | 'detour' | 'swisspark' | 'travelreport';

export enum PageKind {
  map = 'map',
  staticortopic = 'staticortopic',
  landlist = 'landlist',
  segment = 'segment',
  normallegend = 'normallegend',
  slowuplegend = 'slowuplegend',
  feedback = 'feedback',
  tours = 'tours',
  tour = 'tour',
  recordedtrack = 'recordedtrack',
  failure = 'failure',
}

type neutralMapParams = {
  bgLayer?: BaseLayerCode;
  fitBbox?: boolean;
  detours?: boolean;
  logos?: boolean;
  shooting?: boolean;
  photos?: boolean;
  crosshair?: string;
  keepLayers?: boolean;
  centerOnUser?: boolean;
  resolution?: number;
  toursv2?: boolean;
};

/**
 * Bag is a collection of sanitized possible URL parameters.
 */
export type Bag = {
  tourId?: number;
  season?: SeasonCode;
  land?: LandCode;
  neutralMapParams?: neutralMapParams;
  routeCategory?: RouteCategoryCode;
  routeNumber?: number;
  segmentNumber?: number;
  segmentWebdbId?: number;
  placeWebdbId?: number;
  additionalLayerWebdbId?: number;
  sightseeingId?: number;
  stationId?: number;
  slowupId?: number;
  detourId?: number;
  overlayType?: OverlayType;
  overlayContentId?: number;
  lang?: LanguageCode;
  staticOrTopicKey?: string;
  trackId?: string;
  recordedTrackId?: string;
  embed?: 'embed' | 'simple';
};

type NormalizeSplitResult = {
  code: NormalizationCode;
  bag: Bag;
  kind: PageKind;
};

/**
 * Remove the eventual language component of the pathNameSplit and search params.
 * Give priority to lang in path.
 * @param pathNameSplit the pathname splitted on /
 * @param searchParams the URL search params
 * @param defaultLang the default language detected by the browser
 * @param b2b use b2b lang codes
 * @return the language to use
 */
export function removeLangFromUrlParts(
  pathNameSplit: string[],
  searchParams: URLSearchParams,
  defaultLang: LanguageCode,
  b2b = false,
): LanguageCode {
  // Get lang and ensure the search params does not keep
  const splitValue = pathNameSplit[0];
  const searchValue = searchParams.get('lang');
  searchParams.delete('lang');

  if (languageCodes.includes(splitValue as LanguageCode)) {
    pathNameSplit.shift();
    if (b2b && splitValue === 'en') return 'de';
    return splitValue as LanguageCode;
  }
  if (languageCodes.includes(searchValue as LanguageCode)) {
    if (b2b && searchValue === 'en') return 'de';
    return searchValue as LanguageCode;
  }
  return defaultLang;
}

export function splitPathName(pathname: string): string[] {
  return pathname.split('/').filter((part) => part !== '');
}

export type NormalizeURLResult = {
  normalizedURL: string;
  detectedLang: LanguageCode;
  bag: Bag;
  canonical: boolean;
  code: NormalizationCode;
  kind: PageKind;
};

/**
 * Normalize legacy URLs to canonical ones (fix typos, language inconsistencies, renaming, ...)
 * @param urlStr The URL (document.location) to work on
 * @param defaultLang The preferred language of the user
 * @param defaultSeason
 * @param deployPrefix
 * @return the normalized URL conformant to the latest version of the app and some extra data
 */
export function normalizeURL(
  urlStr: string,
  defaultLang: LanguageCode,
  defaultSeason: SeasonCode,
  deployPrefix: string,
  b2b: boolean = false,
): NormalizeURLResult {
  // Parse the URL
  const url = new URL(urlStr);
  const pathNameSplit = splitPathName(
    url.pathname.substring(deployPrefix.length),
  );
  const searchParams = url.searchParams;

  // Handle the case of map.schweizmobil.ch without any Url parameters
  if (url.origin.match(/map.schweizmobil/)) {
    // add a redirect param to the URL to send to map
    searchParams.set('redirect', 'map');
  }

  // Remove the lang so that we handle a single case
  const lang = removeLangFromUrlParts(pathNameSplit, searchParams, defaultLang);
  if (searchParams.has('logo')) {
    searchParams.set('logos', searchParams.get('logo'));
    searchParams.delete('logo');
  }
  if (searchParams.has('highlight')) {
    searchParams.delete('highlight');
  }

  // identify case and normalize parts
  normalizeLayersParams(searchParams);
  const {code, bag, kind} = normalizeSplit(
    pathNameSplit,
    searchParams,
    lang,
    defaultSeason,
    b2b,
  );

  if (code !== CANONICAL) {
    return {
      normalizedURL: url.toString(),
      detectedLang: lang,
      bag: bag,
      canonical: false,
      code: code,
      kind: kind,
    };
  }

  // Reconstruct URL (search is updated by side effect)
  pathNameSplit.unshift(lang);
  url.pathname = `${deployPrefix}/${pathNameSplit.join('/')}`;
  const outUrl = url.toString();
  return {
    normalizedURL: outUrl,
    detectedLang: lang,
    bag,
    canonical: code === CANONICAL && !deployPrefix,
    code: code,
    kind: kind,
  };
}

/*
https://www.schweizmobil.ch/fr/suisse-a-velo/route-04.html
https://www.schweizmobil.ch/en/cycling-in-switzerland/route-04.html
*/
function normalizeLayersParams(params: URLSearchParams) {
  const layers = params.has('layers') ? params.get('layers').split(',') : [];

  if (layers.includes('Accomodation')) {
    layers.splice(layers.indexOf('Accomodation'), 1, 'Accommodation');
  }

  if (layers.includes('Mountainbikeland')) {
    layers.splice(layers.indexOf('Mountainbikeland'), 1, 'Mtbland');
  }

  if (layers.includes('ch.swisstopo.swissboundaries3d-gemeinde-flaeche.fill')) {
    layers.splice(
      layers.indexOf('ch.swisstopo.swissboundaries3d-gemeinde-flaeche.fill'),
      1,
      'municipalities',
    );
  }
  if (layers.includes('ch.swisstopo.swissboundaries3d-kanton-flaeche.fill')) {
    layers.splice(
      layers.indexOf('ch.swisstopo.swissboundaries3d-kanton-flaeche.fill'),
      1,
      'cantons',
    );
  }

  if (layers.length > 0) {
    params.set('layers', layers.join(','));
  }
}

/**
 *
 * @param str some string
 * @return the string without the .html suffix
 */
export function noHTML(str: string): string {
  if (str && str.endsWith('.html')) {
    return str.substring(0, str.length - 5);
  }
  return str;
}

/**
 * @param pathNameSplit
 * @param searchParams
 * @param lang
 * @param defaultSeason
 * @return whether the URL has been normalized
 */
function normalizeSplit(
  pathNameSplit: string[],
  searchParams: URLSearchParams,
  lang: LanguageCode,
  defaultSeason: SeasonCode,
  b2b: boolean,
): NormalizeSplitResult {
  const bag: Bag = {};
  bag.lang = lang;
  if (searchParams.has('embed')) {
    if (searchParams.get('embed') === 'simple') bag.embed = 'simple';
    else bag.embed = 'embed';
  }

  if (pathNameSplit.length === 0 || pathNameSplit[0] === 'index.html') {
    pathNameSplit.length = 0;
    if (searchParams.has('trackId') || searchParams.has('recordedTrackId')) {
      let pageKind;
      bag.trackId = searchParams.get('trackId');
      if (searchParams.has('trackId')) {
        bag.trackId = searchParams.get('trackId');
        pageKind = PageKind.tour;
      } else {
        bag.recordedTrackId = searchParams.get('recordedTrackId');
        pageKind = PageKind.recordedtrack;
      }
      return {code: PARTIAL, bag, kind: pageKind};
    } else {
      if (searchParams.has('orte') || searchParams.has('place')) {
        bag.placeWebdbId = parseInt(
          searchParams.get('orte') || searchParams.get('place'),
          10,
        );
        return {code: PARTIAL, bag, kind: PageKind.map};
      }

      if (searchParams.has('velorental') || searchParams.has('mietstation')) {
        bag.additionalLayerWebdbId = parseInt(
          searchParams.get('velorental') || searchParams.get('mietstation'),
          10,
        );
        bag.overlayType = 'velorental';
        return {code: PARTIAL, bag, kind: PageKind.map};
      }

      if (
        searchParams.has('cycleservice') ||
        searchParams.has('servicestelle')
      ) {
        bag.additionalLayerWebdbId = parseInt(
          searchParams.get('cycleservice') || searchParams.get('servicestelle'),
          10,
        );
        bag.overlayType = 'cycleservice';
        return {code: PARTIAL, bag, kind: PageKind.map};
      }

      if (
        searchParams.has('accommodation') ||
        searchParams.has('uebernachten') ||
        searchParams.has('accomodation')
      ) {
        bag.additionalLayerWebdbId = parseInt(
          searchParams.get('accommodation') ||
            searchParams.get('uebernachten') ||
            searchParams.get('accomodation'),
          10,
        );
        bag.overlayType = 'accommodation';
        return {code: PARTIAL, bag, kind: PageKind.map};
      }

      if (searchParams.has('alpentaxi')) {
        bag.additionalLayerWebdbId = parseInt(
          searchParams.get('alpentaxi'),
          10,
        );
        bag.overlayType = 'alpinetaxi';
        return {code: PARTIAL, bag, kind: PageKind.map};
      }

      if (searchParams.has('swisspark')) {
        bag.additionalLayerWebdbId = parseInt(
          searchParams.get('swisspark'),
          10,
        );
        bag.overlayType = 'swisspark';
        return {code: PARTIAL, bag, kind: PageKind.map};
      }

      if (searchParams.has('poi') || searchParams.has('sightseeing')) {
        bag.sightseeingId = parseInt(
          searchParams.get('poi') || searchParams.get('sightseeing'),
          10,
        );
        return {code: PARTIAL, bag, kind: PageKind.map};
      }

      if (searchParams.has('vkpt') || searchParams.has('station')) {
        bag.stationId = parseInt(
          searchParams.get('vkpt') || searchParams.get('station'),
          10,
        );
        return {code: PARTIAL, bag, kind: PageKind.map};
      }

      if (searchParams.has('slowup')) {
        if (searchParams.get('slowup') === 'all') {
          searchParams.set(
            'layers',
            [...searchParams.getAll('layers'), 'slowup'].join(','),
          );
          searchParams.delete('slowup');
        } else {
          bag.slowupId = parseInt(searchParams.get('slowup'), 10);
          return {code: PARTIAL, bag, kind: PageKind.map};
        }
      }

      if (searchParams.has('detour')) {
        bag.detourId = parseInt(searchParams.get('detour'), 10);
        return {code: PARTIAL, bag, kind: PageKind.map};
      }

      if (searchParams.has('resolution')) {
        const resolution = parseInt(searchParams.get('resolution'), 10);
        bag.neutralMapParams = {
          ...bag.neutralMapParams,
          resolution: resolution,
        };
      }

      if (searchParams.has('centerOnUser')) {
        bag.neutralMapParams = {...bag.neutralMapParams, centerOnUser: true};
      }

      if (
        (searchParams.has('resolution') ||
          searchParams.has('centerOnUser') ||
          (searchParams.has('E') && searchParams.has('N')) ||
          (searchParams.has('X') && searchParams.has('Y')) ||
          (searchParams.has('lon_i') && searchParams.has('lat_i')) ||
          searchParams.has('layers') ||
          searchParams.has('season') ||
          searchParams.has('redirect') ||
          searchParams.has('bgLayer')) &&
        !searchParams.has('route') &&
        !searchParams.has('etappe')
      ) {
        // Case from map.schweizmobil.ch/...
        pathNameSplit.push(mapUrlFragment[lang]);
        return {code: CANONICAL, bag, kind: PageKind.map};
      } else if (
        searchParams.has('land') &&
        (searchParams.has('route') || searchParams.has('etappe'))
      ) {
        // Case for schweizmobil.ch?land=skatingland&route=2
        const {land, route, etappe, season} = Object.fromEntries(searchParams);

        let routeNumber = Number(route);
        let segmentNumber = undefined;
        let landCode = undefined;
        if (isSeasonCode(season)) bag.season = season;
        if (isLandLayer(land)) {
          landCode = apiCodeToLandCode[land];
        }
        if (isLegacyUserLand(land)) {
          landCode = landToLandCode[land];
        }
        if (isAlternativeLandCode(land)) {
          landCode = alternativeCodeToLandCode[land];
        }
        if (landCode && (route || etappe)) {
          bag.land = landCode;
          if (etappe) {
            const numbers: number[] = etappe.split('.').map((n) => Number(n));
            if (!isNaN(numbers[0])) routeNumber = numbers[0];
            if (!isNaN(numbers[1])) segmentNumber = numbers[1];
          }
          bag.routeNumber = routeNumber;
          bag.segmentNumber = segmentNumber;
          pathNameSplit[0] = landUrlFragment[landCode][lang];
          pathNameSplit[1] = `${routeUrlFragment[lang]}-${routeNumber}`;
          if (segmentNumber)
            pathNameSplit[2] = `${segmentUrlFragment[lang]}-${segmentNumber}`;
        }

        // Navigate to the segment page with the map open
        searchParams.set('focus', 'map');
        return {code: CANONICAL, bag, kind: PageKind.segment};
      } else {
        // Case W1: home
        // example: /
        if (b2b) {
          bag.staticOrTopicKey = 'b2b_landpages';
          return {
            code: CANONICAL,
            bag,
            kind: PageKind.staticortopic,
          };
        }
        pathNameSplit[0] = seasonsUrlFragment[defaultSeason][lang];
        bag.season = defaultSeason;
        return {code: CANONICAL, bag, kind: PageKind.landlist};
      }
    }
  }
  const firstPart = noHTML(pathNameSplit[0]);
  const lastPart = noHTML(pathNameSplit.at(-1));
  const secondLastPart = noHTML(pathNameSplit.at(-2));

  if (getAllParts1(mapUrlFragment).includes(firstPart)) {
    if (pathNameSplit.length !== 1) {
      return {code: FAILURE, bag, kind: PageKind.failure};
    }

    // Case W30: neutral map
    // example: /map/?lang=fr&photos=yes&logo=yes&detours=yes&season=summer&bgLayer=pk&resolution=250&E=2631750&N=1189000
    pathNameSplit[0] = mapUrlFragment[lang];

    // set neutral map parameters
    const {
      bgLayer,
      fitBbox,
      season,
      detours,
      shooting,
      photos,
      logos,
      crosshair,
      land,
      route,
      etappe,
      toursv2,
    } = Object.fromEntries(searchParams);

    if (isSeasonCode(season)) bag.season = season;

    bag.neutralMapParams = {
      bgLayer: isBgCode(bgLayer) ? bgLayer : undefined,
      fitBbox: !!fitBbox,
      logos: !logos || logos === 'yes',
      detours: !detours || detours === 'yes',
      photos: !photos || photos === 'yes',
      crosshair: crosshair,
      shooting: !shooting || shooting === 'yes',
      keepLayers: searchParams.has('keepLayers'),
      centerOnUser: searchParams.has('centerOnUser'),
      toursv2: !!toursv2,
    };

    let routeNumber = Number(route);
    let segmentNumber = 0;
    let landCode = undefined;
    if (isLandLayer(land)) {
      landCode = apiCodeToLandCode[land];
    }
    if (isLegacyUserLand(land)) {
      landCode = landToLandCode[land];
    }
    if (isAlternativeLandCode(land)) {
      landCode = alternativeCodeToLandCode[land];
    }
    if (landCode && (route || etappe)) {
      bag.land = landCode;
      if (etappe) {
        const numbers: number[] = etappe.split('.').map((n) => Number(n));
        if (!isNaN(numbers[0])) routeNumber = numbers[0];
        if (!isNaN(numbers[1])) segmentNumber = numbers[1];
      }
      bag.routeNumber = routeNumber;
      bag.segmentNumber = segmentNumber;
    }

    return {code: CANONICAL, bag, kind: PageKind.map};
  }

  if (getAllParts1(toursUrlFragment).includes(firstPart)) {
    // Example /tours
    if (pathNameSplit.length !== 1) {
      return {code: FAILURE, bag, kind: PageKind.failure};
    }
    pathNameSplit[0] = toursUrlFragment[lang];
    return {code: CANONICAL, bag, kind: PageKind.tours};
  }

  if (getAllParts1(tourUrlFragment).includes(firstPart)) {
    // Example /tour/42
    pathNameSplit[0] = tourUrlFragment[lang];
    const tourId = parseInt(pathNameSplit[1], 10);
    if (pathNameSplit.length === 2 || !Number.isNaN(tourId)) {
      bag.tourId = tourId;
    }
    return {code: CANONICAL, bag, kind: PageKind.tour};
  }

  if (getAllParts1(recordedTourUrlFragment).includes(firstPart)) {
    // Example /recordedtrack/42
    pathNameSplit[0] = recordedTourUrlFragment[lang];
    const tourId = parseInt(pathNameSplit[1], 10);
    if (pathNameSplit.length === 2 && !Number.isNaN(tourId)) {
      bag.tourId = tourId;
    }
    return {code: CANONICAL, bag, kind: PageKind.recordedtrack};
  }

  if (getAllParts1(legendUrlFragment).includes(firstPart)) {
    // Example /legend
    pathNameSplit[0] = legendUrlFragment[lang];
    return {code: CANONICAL, bag, kind: PageKind.normallegend};
  }
  if (getAllParts1(slowupLegendUrlFragment).includes(firstPart)) {
    // Example /slowup-legend
    pathNameSplit[0] = slowupLegendUrlFragment[lang];
    return {code: CANONICAL, bag, kind: PageKind.slowuplegend};
  }
  // e.g. /en/unterseiten/disclaimer_en.html or /en/disclaimer_en.html (detour specific disclaimer)
  if (
    firstPart.includes('unterseiten') ||
    getAllParts1(legacyDisclaimerUrlFragment).includes(firstPart)
  ) {
    // Example /closures-detours
    pathNameSplit[0] = detoursUrlFragment[lang];
    bag.staticOrTopicKey = detoursUrlFragment[lang];
    // remove any extra parts
    pathNameSplit.length = 1;
    return {code: CANONICAL, bag, kind: PageKind.staticortopic};
  }
  // e.g. en/media.html
  if (getAllParts1(legacyMediaUrlFragment).includes(firstPart)) {
    pathNameSplit[0] = mediaUrlFragment[lang];
    bag.staticOrTopicKey = mediaUrlFragment[lang];
    return {code: CANONICAL, bag, kind: PageKind.staticortopic};
  }
  // e.g. en/switzerlandmobility-services.html
  if (getAllParts1(legacyChmServiceUrlFragment).includes(firstPart)) {
    pathNameSplit[0] = chmServiceUrlFragment[lang];
    bag.staticOrTopicKey = chmServiceUrlFragment[lang];
    return {code: CANONICAL, bag, kind: PageKind.staticortopic};
  }
  // e.g. en/switzerlandmobility-app-e.html
  if (getAllParts1(legacyMobileUrlFragment).includes(firstPart)) {
    pathNameSplit[0] = mobileUrlFragment[lang];
    bag.staticOrTopicKey = mobileUrlFragment[lang];
    return {code: CANONICAL, bag, kind: PageKind.staticortopic};
  }
  // e.g. en/copyright-pictures.html
  if (getAllParts1(copyrightPhotoUrlFragment).includes(firstPart)) {
    pathNameSplit[0] = copyrightPhotoUrlFragment[lang];
    bag.staticOrTopicKey = copyrightPhotoUrlFragment[lang];
    return {code: CANONICAL, bag, kind: PageKind.staticortopic};
  }

  // e.g. /en/impressum.html
  if (
    firstPart === 'impressum' ||
    getAllParts1(publishingCreditsUrlFragment).includes(firstPart)
  ) {
    // Example /publishing-credits
    pathNameSplit[0] = publishingCreditsUrlFragment[lang];
    bag.staticOrTopicKey = publishingCreditsUrlFragment[lang];
    return {code: CANONICAL, bag, kind: PageKind.staticortopic};
  }
  // e.g. /en/contact
  if (
    firstPart === 'feedback' ||
    getAllParts1(legacyFeedbackUrlFragment).includes(firstPart)
  ) {
    // Example /feedback
    pathNameSplit[0] = feedbackUrlFragment;
    return {code: CANONICAL, bag, kind: PageKind.feedback};
  }
  // e.g. /en/copyright-privacy-policy
  if (getAllParts1(legacyCopyrightUrlFragment).includes(firstPart)) {
    // Example /copyright
    pathNameSplit[0] = copyrightUrlFragment;
    bag.staticOrTopicKey = copyrightUrlFragment;
    return {code: CANONICAL, bag, kind: PageKind.staticortopic};
  }
  // e.g. /en/qrcode?x=260000&y=1200000
  if (firstPart === 'qrcode') {
    pathNameSplit[0] = 'map';
    return {code: CANONICAL, bag, kind: PageKind.map};
  }
  const seasonCode = getCodeForAnyLang(firstPart, seasonsUrlFragment);
  if (seasonCode) {
    // Case C2 + W2: home with season
    // Examples: /ete or /ete.html
    if (pathNameSplit.length !== 1) {
      // there should be no extra part
      return {code: FAILURE, bag, kind: PageKind.failure};
    }
    pathNameSplit[0] = seasonsUrlFragment[seasonCode][lang];
    bag.season = seasonCode;
    return {code: CANONICAL, bag, kind: PageKind.landlist};
  }

  const landCode = getCodeForAnyLang(firstPart, landUrlFragment);
  if (landCode) {
    // route or segment page
    // we accept the land in any language and normalize to the active language
    pathNameSplit[0] = landUrlFragment[landCode][lang];
    bag.land = landCode;
    bag.season = ROUTE_LAYERS[landCode].season;
    if (pathNameSplit.length === 1) {
      // Case W8 + C12: land home
      // Example: /winterwandern or /winterwandern.html
      return {code: CANONICAL, bag, kind: PageKind.landlist};
    }

    // We check the last part of the path because we need to be backward compatible with the old URLs:
    //   /mountainbikeland/routen/nationale-routen.html -> /mountainbikeland/nationale-routen
    const routeCategoryCode = getCodeForAnyLang(
      lastPart,
      routeCategoriesUrlFragment,
    );
    if (routeCategoryCode) {
      pathNameSplit[1] = routeCategoriesUrlFragment[routeCategoryCode][lang];
      // remove any extra parts from the path (backward compatibility)
      pathNameSplit.length = 2;
      bag.routeCategory = routeCategoryCode;
      // Case W10,W11,W12,W13
      return {code: CANONICAL, bag, kind: PageKind.landlist};
    }

    // Handle old syntax route + stage syntax:
    //   /mountainbikeland/etappe1.03 -> /mountainbikeland/route-1/etappe-3
    const routeSegmentNb = getRouteAndSegmentNumbers(lastPart);
    if (routeSegmentNb) {
      const routeNb = routeSegmentNb[0];
      const segmentNb = routeSegmentNb[1];
      bag.routeNumber = routeNb;
      pathNameSplit[1] = `${routeUrlFragment[lang]}-${routeNb}`;
      bag.segmentNumber = segmentNb;
      pathNameSplit[2] = `${segmentUrlFragment[lang]}-${segmentNb}`;
      pathNameSplit.length = 3;
      return {code: CANONICAL, bag, kind: PageKind.segment};
    }

    // Handle old syntax route
    //   /wanderland/routen/route-0381.html -> /wanderland/route-381
    // Handle new syntax route: case C13,C14,C15,C17,C18 + W9
    //   /veloland/route-1
    const routeNb = getIntForAnyPartFragment(lastPart, routeUrlFragment);
    if (routeNb) {
      pathNameSplit[1] = `${routeUrlFragment[lang]}-${routeNb}`;
      pathNameSplit.length = 2;
      bag.routeNumber = routeNb;
      return {code: CANONICAL, bag, kind: PageKind.segment};
    }

    // Handle route + stage syntax:
    //   /veloland/route-1/etappe-1
    const segmentNb = getIntForAnyPartFragment(lastPart, segmentUrlFragment);
    if (segmentNb) {
      const routeNb = getIntForAnyPartFragment(
        secondLastPart,
        routeUrlFragment,
      );
      if (routeNb) {
        bag.routeNumber = routeNb;
        bag.segmentNumber = segmentNb;
        pathNameSplit[1] = `${routeUrlFragment[lang]}-${routeNb}`;
        pathNameSplit[2] = `${segmentUrlFragment[lang]}-${segmentNb}`;
        pathNameSplit.length = 3;
        return {code: CANONICAL, bag, kind: PageKind.segment};
      } else {
        // Handle old syntax stage with webdb id
        //  /mountainbikeland/routen/route/etappe-0113.html -> /mountainbikeland/route-2/etappe-29
        bag.segmentWebdbId = segmentNb;
        return {code: PARTIAL, bag, kind: PageKind.segment};
      }
    }

    // Handle selected routes
    const selectedRoutesCode = getCodeForAnyLang(lastPart, selectedRoutes);
    if (selectedRoutesCode) {
      pathNameSplit[0] = bag.season;
      pathNameSplit.length = 1;
      searchParams.set(
        'filters',
        JSON.stringify(selectedRoutesFilter[selectedRoutesCode]),
      );
      return {code: CANONICAL, bag, kind: PageKind.segment};
    }
  }

  // split the layer part into the layer name and the layer number
  // handles the 'sr' prefix for the old accommodation urls.
  // note: there's always a trailing empty string in the split result because of the capturing group
  // in the regex, see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/split#description
  // Examples:
  //  /place-of-interest-13 -> ['place-of-interest', '13', '']
  //  /accommodation-9 -> ['accommodation', '9', '']
  //  /uebernachten-sr31168 -> ['accommodation', '31168', '']
  const layerPartSplit = lastPart.split(/-(?:sr)?(\d+)/);

  if (oldOverlayDirectUrlFragment['travelreport'][lang] === layerPartSplit[0]) {
    const routeNb = parseInt(layerPartSplit[1], 10);
    if (Number.isNaN(routeNb)) {
      // old travel report overview-page redirect, only keep the land part
      //  /veloland/routen/reiseberichte.html -> /veloland
      pathNameSplit.length = 1;
    } else {
      // old travel report list page for a route
      //  /veloland/routen/reiseberichte-01.html -> /veloland/route-1
      bag.routeNumber = routeNb;
      pathNameSplit[1] = `${routeUrlFragment[lang]}-${routeNb}`;
      pathNameSplit.length = 2;
    }
    return {code: CANONICAL, bag, kind: PageKind.segment};
  }

  // redirect old URLs with 'rkey':
  //   /wanderland/orte/rkey/1276 -> /ort-443
  //   /orte/rkey/1276 -> /ort-443
  if (secondLastPart === 'rkey') {
    bag.placeWebdbId = parseInt(layerPartSplit[0], 10);
    return {code: PARTIAL, bag, kind: PageKind.map};
  }

  // redirect old travel report urls:
  //   /de/veloland/routen/reiseberichte/reisebericht-020059.html -> /de/veloland/reisebericht-59
  //   /fr/suisse-a-velo/itineraires/carnets-de-route/reisebericht-010007.html -> /fr/suisse-a-velo/carnet-de-voyage-7
  if (secondLastPart === oldOverlayDirectUrlFragment['travelreport'][lang]) {
    const oldId = lastPart.match(/\d+/)[0];
    // long format is 0X0XXX, short format is 0XXX
    const newId =
      oldId.length > 4 ? parseInt(oldId.substring(2), 10) : parseInt(oldId, 10);
    pathNameSplit[1] = `${overlayDirectUrlFragment['travelreport'][lang]}-${newId}`;
    pathNameSplit.length = 2;
    return {code: CANONICAL, bag, kind: PageKind.landlist};
  }

  // special redirection case for old sightseeing urls
  //   /en/hiking-in-switzerland/poi/13 -> /en/sightseeing-13
  //   /en/poi/13 -> /en/sightseeing-13
  if (secondLastPart === 'poi') {
    const layerId = parseInt(layerPartSplit[0], 10);
    return normalizePoiUrl(pathNameSplit, lang, bag, 'sightseeing', layerId);
  }

  // standard case for poi urls
  const layerCode = getCodeForAnyLang(
    layerPartSplit[0],
    overlayDirectUrlFragment,
  );
  const layerId = parseInt(layerPartSplit[1]);
  if (isNaN(layerId)) {
    bag.staticOrTopicKey = firstPart;
    return {code: CANONICAL, bag, kind: PageKind.staticortopic};
  }
  return normalizePoiUrl(pathNameSplit, lang, bag, layerCode, layerId);
}

function normalizePoiUrl(
  pathNameSplit: string[],
  lang: LanguageCode,
  bag: Bag,
  layerCode: OverlayType,
  layerId: number,
): NormalizeSplitResult {
  if (layerCode === undefined || layerCode === undefined) {
    return {code: FAILURE, bag, kind: PageKind.map};
  }
  // remove extra parts from the path (backward compatibility):
  //   /wanderland/services/orte/ort-0258.html -> /ort-0258
  // The land part must only be kept for travel reports
  const index = layerCode === 'travelreport' ? 1 : 0;
  pathNameSplit[index] =
    `${overlayDirectUrlFragment[layerCode][lang]}-${layerId}`;
  pathNameSplit.length = index + 1;
  bag.overlayType = layerCode;
  bag.overlayContentId = layerId;
  return {code: CANONICAL, bag, kind: PageKind.map};
}

export function normalizeB2bUrl(
  urlStr: string,
  deployPrefix: string,
  defaultLang: B2bLanguageCode,
) {
  const url = new URL(urlStr);
  const pathNameSplit = splitPathName(
    url.pathname.substring(deployPrefix.length),
  );
  const searchParams = url.searchParams;
  const lang = removeLangFromUrlParts(
    pathNameSplit,
    searchParams,
    defaultLang,
    true,
  );
  pathNameSplit.unshift(lang);
  url.pathname = `${deployPrefix}/${pathNameSplit.join('/')}`;
  return url.toString();
}
