/**
 * This file is for the definition and parsing of url params.
 */

import type {OverlayType} from './urlNormalizer.ts';
import type {
  LandCode,
  RouteCategoryCode,
  SeasonCode,
} from '../layers/layerTypes.ts';
import type {LanguageCode} from '../i18n.ts';
import {languageCodes} from '../i18n.ts';

/** Seasons parts to use in URLs, per language */
export const seasonsUrlFragment: Record<
  SeasonCode,
  Record<LanguageCode, string>
> = {
  summer: {
    en: 'summer',
    fr: 'ete',
    de: 'sommer',
    it: 'estate',
  },
  winter: {
    en: 'winter',
    de: 'winter',
    fr: 'hiver',
    it: 'inverno',
  },
};

/**
 * Land parts to use in URLs, per language
 * Keep keys same as ROUTE_LAYERS
 */
export const landUrlFragment: Record<LandCode, Record<LanguageCode, string>> = {
  hike: {
    en: 'hiking-in-switzerland',
    fr: 'suisse-a-pied',
    it: 'svizzera-a-piedi',
    de: 'wanderland',
  },
  cycle: {
    en: 'cycling-in-switzerland',
    fr: 'suisse-a-velo',
    de: 'veloland',
    it: 'svizzera-in-bici',
  },
  mountainbike: {
    en: 'mountainbiking-in-switzerland',
    fr: 'suisse-a-vtt',
    de: 'mountainbikeland',
    it: 'svizzera-in-mountain-bike',
  },
  skate: {
    en: 'skating-in-switzerland',
    fr: 'suisse-en-rollers',
    de: 'skatingland',
    it: 'svizzera-in-skating',
  },
  canoe: {
    en: 'canoeing-in-switzerland',
    fr: 'suisse-en-canoe',
    de: 'kanuland',
    it: 'svizzera-in-canoa',
  },
  winterhike: {
    en: 'winter-hiking',
    fr: 'randonnees-hivernales',
    de: 'winterwandern',
    it: 'escursioni-invernali',
  },
  snowshoe: {
    en: 'snowshoe-trekking',
    fr: 'randonnees-en-raquettes',
    de: 'schneeschuhwandern',
    it: 'ciaspolate',
  },
  crosscountry: {
    en: 'cross-country-skiing',
    fr: 'ski-de-fond',
    de: 'langlaufen',
    it: 'sci-di-fondo',
  },
  sledge: {
    en: 'sledging',
    fr: 'luge',
    de: 'schlitteln',
    it: 'slittino',
  },
};

export const overlayDirectUrlFragment: Record<
  OverlayType,
  Record<LanguageCode, string>
> = {
  place: {
    en: 'place',
    de: 'ort',
    fr: 'lieux',
    it: 'localita',
  },
  sightseeing: {
    en: 'place-of-interest',
    de: 'sehenswuerdigkeit',
    fr: 'curiosite',
    it: 'curiosita',
  },
  accommodation: {
    en: 'accommodation',
    de: 'uebernachten',
    fr: 'hebergements',
    it: 'alloggio',
  },
  cycleservice: {
    en: 'cycle-service',
    de: 'veloservice',
    fr: 'service-velo',
    it: 'assistenza-bici',
  },
  velorental: {
    en: 'bicycle-and-e-bike-rental',
    de: 'miete-velo-und-e-bike',
    fr: 'location-de-velos-et-e-bikes',
    it: 'noleggio-bici-ed-e-bike',
  },
  alpinetaxi: {
    en: 'taxi-alpin',
    de: 'alpentaxi',
    fr: 'taxi-alpin',
    it: 'taxi-alpino',
  },
  detour: {
    en: 'closures-and-detours',
    de: 'sperrungen-und-umleitungen',
    fr: 'fermetures-et-deviations',
    it: 'chiusure-e-deviazioni',
  },
  swisspark: {
    en: 'swiss-parks',
    de: 'schweizer-paerke',
    fr: 'parcs-suisses',
    it: 'parchi-svizzeri',
  },
  station: {
    en: 'public-transport',
    de: 'oeffentlicher-verkehr',
    fr: 'transports-publics',
    it: 'trasporti-pubblici',
  },
  slowup: {
    en: 'slowup',
    de: 'slowup',
    fr: 'slowup',
    it: 'slowup',
  },
  travelreport: {
    en: 'travel-report',
    de: 'reisebericht',
    fr: 'carnet-de-voyage',
    it: 'relazione-di-viaggio',
  },
};

// category name from the old website. Used to redirect old URLs
export const oldOverlayDirectUrlFragment: Record<
  'travelreport' | 'place' | 'sightseeing',
  Record<LanguageCode, string>
> = {
  travelreport: {
    en: 'travel-reports',
    de: 'reiseberichte',
    fr: 'carnets-de-route',
    it: 'relazioni-di-viaggio',
  },
  place: {
    en: 'places',
    de: 'orte',
    fr: 'lieux',
    it: 'localita',
  },
  sightseeing: {
    en: 'places-of-interest',
    de: 'sehenswuerdigkeiten',
    fr: 'curiosites',
    it: 'curiosita',
  },
};

/** Category parts to use in URLs, per language */
export const routeCategoriesUrlFragment: Record<
  RouteCategoryCode,
  Record<LanguageCode, string>
> = {
  national: {
    en: 'national-routes',
    fr: 'itineraires-nationaux',
    it: 'percorsi-nazionali',
    de: 'nationale-routen',
  },
  regional: {
    en: 'regional-routes',
    fr: 'itineraires-regionaux',
    it: 'percorsi-regionali',
    de: 'regionale-routen',
  },
  local: {
    en: 'local-routes',
    fr: 'itineraires-locaux',
    it: 'percorsi-locali',
    de: 'lokale-routen',
  },
  handicap: {
    en: 'obstacle-free-routes',
    fr: 'chemins-sans-obstacles',
    it: 'sentieri-senza-barriere',
    de: 'hindernisfreie-wege',
  },
};

/**
 * Extract int suffix from strings like "route-042".
 * @param str some arbitrary string
 * @param prefix some string like "route", "etappe", ...
 * @return a truthy number on success
 */
export function parseIntWithPrefix(str: string, prefix: string): number {
  if (str && str.startsWith(prefix)) {
    str = str.substring(prefix.length);
    if (str[0] === '-') {
      str = str.substring(1);
    }
    return parseInt(str, 10);
  }
  return 0;
}

// Get a route and segment number from an old style URL fragment:
// 'etappe1.03' -> [1, 3]
export function getRouteAndSegmentNumbers(
  str: string,
): [number, number] | undefined {
  if (!str) {
    return undefined;
  }
  for (const lang of languageCodes) {
    const prefix = segmentUrlFragment[lang];
    if (str.startsWith(prefix)) {
      str = str.substring(prefix.length);
      const numbers = str.split('.').map((number) => parseInt(number, 10));
      if (
        numbers.length === 2 &&
        numbers.every((number) => !Number.isNaN(number))
      ) {
        return <[number, number]>numbers;
      } else {
        // invalid format
        return undefined;
      }
    }
  }
  return undefined;
}

/**
 * The translations for the map URL fragment.
 */
export const mapUrlFragment: Record<LanguageCode, string> = {
  en: 'map',
  de: 'map',
  fr: 'map',
  it: 'map',
};

/**
 * The translations for the route URL fragment.
 */
export const routeUrlFragment: Record<LanguageCode, string> = {
  en: 'route',
  de: 'route',
  fr: 'itineraire',
  it: 'percorso',
};

/**
 * The translations for the legend URL fragment.
 */
export const legendUrlFragment: Record<LanguageCode, string> = {
  en: 'legend',
  it: 'didascalie',
  fr: 'legendes',
  de: 'legende',
};

/**
 * The translations for the slowup legend URL fragment.
 */
export const slowupLegendUrlFragment: Record<LanguageCode, string> = {
  en: 'slowup-legend',
  it: 'slowup-didascalie',
  fr: 'slowup-legendes',
  de: 'slowup-legende',
};

export const publishingCreditsUrlFragment: Record<LanguageCode, string> = {
  en: 'publishing-credits',
  de: 'impressum',
  fr: 'impressum',
  it: 'sigla-editoriale',
};

export const legacyMediaUrlFragment: Record<LanguageCode, string> = {
  en: 'media',
  de: 'medien',
  fr: 'medias',
  it: 'media',
};

export const mediaUrlFragment: Record<LanguageCode, string> = {
  en: 'for-media',
  de: 'fuer-medien',
  fr: 'pour-les-medias',
  it: 'per-i-media',
};

export const legacyChmServiceUrlFragment: Record<LanguageCode, string> = {
  en: 'switzerlandmobility-services',
  de: 'schweizmobil-services',
  fr: 'services-de-suissemobile',
  it: 'servizi-di-svizzeramobile',
};

export const chmServiceUrlFragment: Record<LanguageCode, string> = {
  en: 'switzerlandmobility-partners',
  de: 'schweizmobil-partner',
  fr: 'suissemobile-partenaires',
  it: 'svizzeramobile-partner',
};

export const chmServiceRedirect: Record<LanguageCode, string> = {
  en: 'https://schweizmobil.info/',
  de: 'https://schweizmobil.info/',
  fr: 'https://schweizmobil.info/fr',
  it: 'https://schweizmobil.info/it',
};

export const legacyMobileUrlFragment: Record<LanguageCode, string> = {
  en: 'switzerlandmobility-app-e',
  de: 'app-schweizmobil-d',
  fr: 'application-de-suissemobile-f',
  it: 'applicazione-di-svizzeramobile-i',
};

export const mobileUrlFragment: Record<LanguageCode, string> = {
  en: 'switzerlandmobility-app',
  de: 'app-schweizmobil',
  fr: 'appli-suissemobile',
  it: 'app-svizzeramobile',
};

export const legacyDisclaimerUrlFragment: Record<LanguageCode, string> = {
  en: 'disclaimer_en',
  it: 'disclaimer_it',
  fr: 'disclaimer_fr',
  de: 'disclaimer_de',
};

export const detoursUrlFragment: Record<LanguageCode, string> = {
  en: 'closures-detours',
  it: 'chiusure-deviazioni',
  fr: 'fermetures-deviations',
  de: 'sperrungen-umleitungen',
};

export const feedbackUrlFragment = 'feedback';
export const legacyFeedbackUrlFragment: Record<LanguageCode, string> = {
  en: 'contact',
  de: 'kontakt',
  fr: 'contact',
  it: 'contatto',
};

export const copyrightUrlFragment = 'copyright';
export const legacyCopyrightUrlFragment: Record<LanguageCode, string> = {
  en: 'copyright-privacy-policy',
  de: 'copyright-datenschutzerklaerung',
  fr: 'copyright-protection-des-donnees',
  it: 'copyright-e-dichiarazione-sulla-protezione-dei-dati',
};

export const copyrightPhotoUrlFragment: Record<LanguageCode, string> = {
  en: 'copyright-pictures',
  de: 'copyright-fotos',
  fr: 'copyright-photos',
  it: 'copyright-immagini',
};

export const schmPlusInstructionsUrlFragment: Record<LanguageCode, string> = {
  en: 'instructions-beta-version',
  de: 'anleitung-beta-version',
  fr: 'mode-demploi-version-beta',
  it: 'istruzioni-versione-beta',
};

export const schmPlusAccountUrlFragment: Record<LanguageCode, string> = {
  en: 'my_account',
  de: 'konto',
  fr: 'compte',
  it: 'account',
};

/**
 *
 * @param urlPart URL fragment to parse
 * @param source provider of prefixes
 * @return a truthy int on success
 */
export function getIntForAnyPartFragment(
  urlPart: string,
  source: Record<LanguageCode, string>,
): number {
  for (const lang of languageCodes) {
    const prefix = source[lang];
    const nb = parseIntWithPrefix(urlPart, prefix);
    if (nb) {
      return nb;
    }
  }
  return Number.NaN;
}

/**
 * The translations for the segment URL fragment.
 */
export const segmentUrlFragment: Record<LanguageCode, string> = {
  en: 'stage',
  de: 'etappe',
  fr: 'etape',
  it: 'tappa',
};

export function getAllParts1(source: Record<LanguageCode, string>): string[] {
  const found = new Set<string>();
  for (const lang of languageCodes) {
    found.add(source[lang]);
  }
  return Array.from(found.values());
}

export function getAllParts2(
  source: Record<any, Record<LanguageCode, string>>,
): string[] {
  const found = new Set<string>();
  for (const code in source) {
    for (const lang of languageCodes) {
      found.add(source[code][lang]);
    }
  }
  return Array.from(found.values());
}

// return undefined if there is no match (this useful in the url normalizer, for example)
export function getCodeForAnyLang<T extends string>(
  urlPart: string,
  source: Record<T, Record<LanguageCode, string>>,
): T | undefined {
  for (const code in source) {
    for (const lang of languageCodes) {
      if (source[code][lang] === urlPart) {
        return code;
      }
    }
  }
  return undefined;
}

// return undefined if there is no match (this useful in the router, for example)
export function getCodeForLang<T extends string>(
  lang: LanguageCode,
  urlPart: string,
  source: Record<T, Record<LanguageCode, string>>,
): T | undefined {
  for (const code in source) {
    if (source[code][lang] === urlPart) {
      return code;
    }
  }
  return undefined;
}

/**
 * The default season depends on the time of the year.
 * @return the url param for the current season
 */
export function getCurrentDefaultSeasonCode(): SeasonCode {
  /**
   * Summer start and end dates used to determine the season.
   * The year is not important so it's set to 1970.
   */
  const summerStart = new Date('1970-03-15');
  const summerEnd = new Date('1970-11-21');

  const now = new Date();
  now.setFullYear(1970);
  const nowMillis = now.getTime();
  if (nowMillis >= summerStart.getTime() && nowMillis <= summerEnd.getTime()) {
    return 'summer';
  } else {
    return 'winter';
  }
}

export function createGradeFitnessStaticPageUrl(
  gradeOrFitness: 'grade' | 'fitness',
  land: LandCode,
  lang: LanguageCode,
): string {
  const landShorters: Record<LandCode, string> = {
    hike: 'wl',
    cycle: 'vl',
    mountainbike: 'ml',
    skate: 'sl',
    winterhike: 'ww',
    canoe: 'kl',
    snowshoe: 'ss',
    crosscountry: 'll',
    sledge: 'sc',
  };

  const gradeFitnessPart: Record<
    typeof gradeOrFitness,
    Record<LanguageCode, string>
  > = {
    grade: {
      en: 'grade',
      it: 'tecnica',
      fr: 'techniquement',
      de: 'technik',
    },
    fitness: {
      en: 'fitness-level',
      it: 'forma-fisica',
      fr: 'physiquement',
      de: 'kondition',
    },
  };

  return `${gradeFitnessPart[gradeOrFitness][lang]}-${landShorters[land]}`;
}

/**
 * The translations for the tours URL fragment.
 */
export const toursUrlFragment: Record<LanguageCode, string> = {
  en: 'tours',
  it: 'tours',
  fr: 'tours',
  de: 'tours',
};

/**
 * The translations for the tours URL fragment.
 */
export const tourUrlFragment: Record<LanguageCode, string> = {
  en: 'tour',
  it: 'tour',
  fr: 'tour',
  de: 'tour',
};

/**
 * The translations for the recorded track URL fragment.
 */
export const recordedTourUrlFragment: Record<LanguageCode, string> = {
  en: 'recordedtrack',
  it: 'recordedtrack',
  fr: 'recordedtrack',
  de: 'recordedtrack',
};

export function getShareLink(
  langCode: LanguageCode,
  href: string,
  params?: URLSearchParams,
) {
  const isTourPage = href.includes(`/${tourUrlFragment[langCode]}/`);
  const isToursPage = href.includes(`/${toursUrlFragment[langCode]}`);
  const isRecordedTrackPage = href.includes(
    `/${recordedTourUrlFragment[langCode]}/`,
  );
  if (isTourPage || isToursPage || isRecordedTrackPage) {
    const mapHref = href.replace(
      /(tour|recordedtrack|tours)(.*)/,
      `${mapUrlFragment[langCode]}`,
    );
    const url = new URL(mapUrlFragment[langCode], mapHref);
    if (params) {
      url.search = params.toString();
    }
    return url.href;
  }
  return href;
}

export function getTourIdFromHref(
  href: string,
  langCode: LanguageCode,
): string | undefined {
  return getTourOrRecordedTourIdFromHref(href, tourUrlFragment[langCode]);
}

export function getRecordedTourIdFromHref(
  href: string,
  langCode: LanguageCode,
): string | undefined {
  return getTourOrRecordedTourIdFromHref(
    href,
    recordedTourUrlFragment[langCode],
  );
}

function getTourOrRecordedTourIdFromHref(
  href: string,
  prefix: string,
): string | undefined {
  const tourIdRe = new RegExp(`/${prefix}/([\\d]+)`);
  const tourId = href.match(tourIdRe);
  return tourId ? tourId[1] : undefined;
}
