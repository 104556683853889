import {
  disableKvp$,
  season$,
  urlSearchParamsChanged$,
} from '../stores/store-main.ts';
import {getLayerTreeCodes} from '../layers/layerHelpers.ts';
import {
  bgLayer$,
  visibleAdditionalRouteLayers$,
  visibleLayers$,
} from '../stores/store-map.ts';
import type View from 'ol/View';
import type {LandCode, LayertreeCode} from '../layers/layerTypes.ts';
import {landUrlFragment, getCodeForAnyLang} from './urlParams.ts';
import {IMAGE_BASE_URL} from '../envs.ts';

export function getURLSearchParams(): URLSearchParams {
  // This is a workaround for SSR.
  if (typeof window === 'undefined') return new URLSearchParams();
  return new URLSearchParams(location.search);
}

export function isDetoursEnabled(): boolean {
  const detours = getURLSearchParams().get('detours');
  return !detours || detours === 'yes';
}

export function isEmbed() {
  return location?.search.includes('embed');
}

export function isShootingEnabled(): boolean {
  const shooting = getURLSearchParams().get('shooting');
  return !shooting || shooting === 'yes';
}

export function isPhotosEnabled(): boolean {
  const photos = getURLSearchParams().get('photos');
  return !photos || photos === 'yes';
}

export function isLogosEnabled(): boolean {
  const logos = getURLSearchParams().get('logos');
  return !logos || logos === 'yes';
}

export function isSVGLogoEnabled(): boolean {
  return getURLSearchParams().get('svgLogo') === 'yes';
}

export const FILTER_PARAMS = [
  'f_land',
  'f_categories',
  'f_grades',
  'f_gradesSkiing',
  'f_fitness',
  'f_canton',
  'f_bookable',
  'f_day',
  'f_group',
  'f_structures',
];

export function getLayersForLayertree(
  searchParams?: URLSearchParams,
): LayertreeCode[] {
  const params = searchParams || getURLSearchParams();
  const season = params.get('season');
  const layers =
    params.get('layers') === ''
      ? []
      : params.get('layers')?.toLowerCase().split(',');
  if (!layers) return [];
  if (layers.includes('accomodation')) {
    layers.splice(
      layers.findIndex((v) => v === 'accomodation'),
      1,
      'accommodation',
    );
  }
  if (layers.includes('wildlifearea') || layers.includes('wildlifereserve')) {
    const areaIndex = layers.findIndex((v) => v === 'wildlifearea');
    const reserveIndex = layers.findIndex((v) => v === 'wildlifereserve');
    if (areaIndex > -1) delete layers[areaIndex];
    if (reserveIndex > -1) delete layers[reserveIndex];
    layers.push('wildlife');
  }
  if (layers.includes('alpentaxi')) {
    layers.splice(
      layers.findIndex((v) => v === 'alpentaxi'),
      1,
      'alpinetaxi',
    );
  }
  if (layers.includes('services')) {
    const winterServices = ['place', 'sightseeing'];
    const summerServices = [...winterServices, 'velorental', 'cycleservice'];
    const services = season === 'winter' ? winterServices : summerServices;
    layers.splice(
      layers.findIndex((v) => v === 'services'),
      1,
      ...services,
    );
  }
  return getLayerTreeCodes(layers);
}

export function getMapKvp(view: View): URLSearchParams {
  const params = getURLSearchParams();
  const center = view.getCenter();
  const resolution = view.getResolution();
  if (center) {
    params.set('E', center[0].toFixed(0));
    params.set('N', center[1].toFixed(0));
  }
  if (resolution) params.set('resolution', resolution.toFixed(2));
  params.set('bgLayer', bgLayer$.getValue());
  const layers = visibleLayers$.getValue();
  params.set('layers', `${layers.join(',')}`);
  if (!params.get('detours') || params.get('detours') !== 'no') {
    params.set(
      'detours',
      visibleAdditionalRouteLayers$.getValue().detours ? 'yes' : 'no',
    );
  }
  if (!params.get('shooting') || params.get('shooting') !== 'no') {
    params.set(
      'shooting',
      visibleAdditionalRouteLayers$.getValue().shooting ? 'yes' : 'no',
    );
  } else if (params.has('shooting')) {
    params.delete('shooting');
  }
  if (!params.get('logos') || params.get('logos') !== 'no') {
    params.set(
      'logos',
      visibleAdditionalRouteLayers$.getValue().logos ? 'yes' : 'no',
    );
  }
  if (!params.get('photos') || params.get('photos') !== 'no') {
    params.set(
      'photos',
      visibleAdditionalRouteLayers$.getValue().photos ? 'yes' : 'no',
    );
  }
  if (!params.get('season')) {
    params.set('season', season$.getValue());
  }
  return params;
}

export function crosscountryBaseNetworkShown() {
  const params = getURLSearchParams();
  const baseNetworkParam = params.get('crosscountryBaseNetwork');
  return !!(baseNetworkParam && baseNetworkParam === 'true');
}

const persistentKeys = ['toursv2'];
const persistentUrlParams = new URLSearchParams();
export function getPersistentSearchParams() {
  getInitialSearchParams();
  return persistentUrlParams;
}

let initialSearchParams: URLSearchParams;
export function getInitialSearchParams(): URLSearchParams {
  if (!initialSearchParams) {
    initialSearchParams = new URLSearchParams(document.location.search);
    for (const [k, v] of initialSearchParams.entries()) {
      if (persistentKeys.includes(k)) {
        persistentUrlParams.append(k, v);
      }
    }
  }
  // we return a copy to ensure no one messes with the initial search params.
  return new URLSearchParams(initialSearchParams);
}

export function setURLSearchParams(params: URLSearchParams, force = false) {
  if (disableKvp$.getValue() && !force) return;
  window.history.replaceState(
    {},
    '',
    `${location.pathname}${params.toString() ? `?${params}` : ''}`,
  );
  urlSearchParamsChanged$.next(params);
}

export function getURLSearchParam(key: string): string | null {
  const params = getURLSearchParams();
  return params.get(key);
}

export function setURLSearchParam(key: string, value: string) {
  const params = getURLSearchParams();
  params.set(key, value);
  setURLSearchParams(params);
}

export function getMapResolution(): number {
  const params = getURLSearchParams();
  return Number(params.get('resolution'));
}

export function getLandCodeFromUrl(): LandCode | undefined {
  const splitUrl = location.pathname.split('/');
  return getCodeForAnyLang(splitUrl[2], landUrlFragment);
}

export function getKeycloakLoginEnabled() {
  const loginEnabled = getURLSearchParams().get('loginEnabled');
  return loginEnabled === 'yes' || loginEnabled === 'true';
}

export function isToursV2() {
  const toursv2 = getURLSearchParams().get('toursv2');
  return toursv2 === 'yes' || toursv2 === 'true';
}

export function hasFilterUrlParam() {
  const filtersUrlParams = getURLSearchParams();
  const hasFilterParams = FILTER_PARAMS.some((filter) =>
    filtersUrlParams.has(filter),
  );
  // Old 'filters' param still need to work for direct links
  const hasOldFilterParams = !!filtersUrlParams.get('filters');
  return hasFilterParams || hasOldFilterParams;
}

export const getPhotoUrl = (code: string, status: 'valid' | 'temp') => {
  return `${IMAGE_BASE_URL}${status}/${code}_big.jpg`;
};

export function toursFeedbackParamCheck(): boolean {
  const params = getURLSearchParams();
  const hasFeedbackParam = params.has('toursFeedback');
  if (hasFeedbackParam) {
    params.delete('toursFeedback');
    setURLSearchParams(params, true);
  }
  return hasFeedbackParam;
}

export function getRedirectParam(): string | null {
  const redirect = getURLSearchParams().get('redirect');
  return redirect ? decodeURIComponent(redirect) : redirect;
}
