export function empty(str: string): boolean {
  return str === undefined || str === null || str.trim().length === 0;
}

export function truncate(str: string, n = 40): string {
  return str && str.length > n ? str.slice(0, n - 1) + '…' : str;
}

export function replaceAll(str: string, find: string, replace: string) {
  if (!str) return '';
  return str.replace(new RegExp(find, 'g'), replace);
}

export function normalizePhoneNumber(tel: string) {
  if (!tel) return '';
  return replaceAll(tel.replace('(0)', ''), ' ', '');
}

export function prettifyUrl(url: string) {
  if (!url) return '';
  return url.replace(/^http(s)?:\/\/(www.)?|^www./g, '');
}

export function isEmailValid(email: string): boolean {
  if (!email?.length) return false;
  return !!email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
}

export function escapeRegExp(string: string) {
  return string ? string.replace(/[\\^$.*+?()[\]{}|]/g, '\\$&') : string;
}
