import {KEYCLOAK_ISSUER} from '../envs.ts';
import Client from '@geoblocks/oidcjs';
import {PageKind, normalizeURL} from '../urlHandling/urlNormalizer.ts';
import {resolveDeployPrefix} from '../initialization/deployPrefixResolver.ts';
import {getLanguageCode} from '../i18n.ts';
import {createMainURL} from '../urlHandling/urlCreate.ts';

const OIDC_CLIENT_ID = 'schweizmobil-website';

function createAppPostLogoutURL(): string {
  // See https://jira.camptocamp.com/browse/GSCHM-3764?focusedCommentId=346661&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-346661
  const l = document.location;
  const lang = getLanguageCode();
  const deployPrefix = resolveDeployPrefix();
  const normalized = normalizeURL(l.toString(), lang, 'summer', deployPrefix);
  switch (normalized.kind) {
    case PageKind.tours:
    case PageKind.tour:
    case PageKind.recordedtrack:
      return document.location.origin + createMainURL(lang);
    default:
      return document.location.toString();
  }
}

export function logoutFromKeycloak(idToken: string) {
  // Also logout from the SSO itself (not only the app)
  const newLocation = new URL(
    `${KEYCLOAK_ISSUER}/protocol/openid-connect/logout`,
  );
  newLocation.searchParams.append(
    'post_logout_redirect_uri',
    document.location.origin + '/',
  );
  newLocation.searchParams.append('client_id', OIDC_CLIENT_ID);
  newLocation.searchParams.append('id_token_hint', idToken);
  localStorage.setItem('appPostLogoutURL', createAppPostLogoutURL());
  document.location = newLocation.toString();
}

export const oidcClient = new Client(
  {
    // Checking the location object because this code is used in the server side rendering
    redirectUri:
      typeof location === 'object'
        ? `${location.origin}/`
        : 'https://schweizmobil.ch/',
    clientId: OIDC_CLIENT_ID,
    pkce: true,
    checkToken: async () => {
      return true;
    },
  },
  {
    authorization_endpoint: `${KEYCLOAK_ISSUER}/protocol/openid-connect/auth`,
    token_endpoint: `${KEYCLOAK_ISSUER}/protocol/openid-connect/token`,
    logout_endpoint: `${KEYCLOAK_ISSUER}/protocol/openid-connect/logout`,
    userinfo_endpoint: `${KEYCLOAK_ISSUER}/protocol/openid-connect/userinfo`,
  },
);
