import type {GroupFilter} from '../apis/api-groups.ts';
import type {RouteOrSegmentFilters} from '../apis/api-segments.ts';
import {defaultFilterValues} from '../helpers/filter.ts';
import type {LanguageCode} from '../i18n.ts';
import {seasonFromLandCode} from '../layers/layerHelpers.ts';
import type {LandCode} from '../layers/layerTypes.ts';

function getFilter(land: LandCode, group: number): RouteOrSegmentFilters {
  const filterValues = {
    ...defaultFilterValues(),
    land: land,
    season: seasonFromLandCode(land),
  };
  filterValues.specialInterests.day = true;
  filterValues.specialInterests.groups[land] = [
    {
      number: group,
      checked: true,
    } as GroupFilter,
  ];

  return filterValues;
}

export const selectedRoutesFilter = {
  'top32-walks': getFilter('hike', 2),
  'cultural-routes-of-switzerland': getFilter('hike', 3),
  'familiy-hiking-tours': getFilter('hike', 4),
  'panoramic-hikes': getFilter('hike', 5),
  'hiking-in-swiss-parks': getFilter('hike', 6),
  'hikes-near-the-water': getFilter('hike', 7),
  'new-routes-of-2023-WL': getFilter('hike', 12),

  'touris-with-a-rented-bike': getFilter('cycle', 2),
  'e-bike-tours': getFilter('cycle', 3),
  'family-cycling-tours': getFilter('cycle', 4),
  'cycling-tours-near-the-water': getFilter('cycle', 5),
  'cycling-in-swiss-parks': getFilter('cycle', 6),
  'circular-cycling-tours': getFilter('cycle', 7),
  'new-routes-of-2023-VL': getFilter('cycle', 12),

  'single-trail-tours': getFilter('mountainbike', 2),
  'pleasure-tours-for-beginners': getFilter('mountainbike', 3),
  'mountainbiking-in-swiss-parks': getFilter('mountainbike', 4),
  'Tours-with-a-rented-mountainbike': getFilter('mountainbike', 5),
  'tours-with-an-e-mountain-bike': getFilter('mountainbike', 6),
  'new-routes-of-2023-ML': getFilter('mountainbike', 9),

  'winter-hiking-in-swiss-parks': getFilter('winterhike', 2),
  'winter-hikes-with-children': getFilter('winterhike', 4),
  'winter-hiking-loop-trails': getFilter('winterhike', 8),
  'the-new-paths-of-2022-2023': getFilter('winterhike', 11),

  'snowshoe-trails-in-swiss-parks': getFilter('snowshoe', 2),
  'snowshoe-trails-with-summit-experience': getFilter('snowshoe', 3),
  'snowshoe-hikes-with-children': getFilter('snowshoe', 4),
  'snowshoe-loop-trails': getFilter('snowshoe', 8),
  'the-new-snowshoe-trails-of-2022-2023-1': getFilter('snowshoe', 11),

  'cross-country-ski-trail-in-swiss-parks-2': getFilter('crosscountry', 2),
  'the-new-trails-of-2022-2023': getFilter('crosscountry', 11),

  'sledging-in-swiss-parks': getFilter('sledge', 2),
  'night-sledging': getFilter('sledge', 3),
};

export const selectedRoutes: Record<
  keyof typeof selectedRoutesFilter,
  Record<LanguageCode, string>
> = {
  'top32-walks': {
    en: 'top32-walks',
    fr: '32-randos-top',
    de: 'top-32-wanderungen',
    it: '32-escursioni-top',
  },
  'familiy-hiking-tours': {
    en: 'familiy-hiking-tours',
    fr: 'Randonnees-familiales',
    de: 'familienwanderungen',
    it: 'escursioni-per-famiglie',
  },
  'cultural-routes-of-switzerland': {
    en: 'cultural-routes-of-switzerland',
    fr: 'itineraires-culturels-en-suisse',
    de: 'kulturwege-schweiz',
    it: 'itinerari-culturali-della-svizzera',
  },
  'hiking-in-swiss-parks': {
    en: 'hiking-in-swiss-parks',
    fr: 'randonnees-dans-les-parcs-suisse',
    de: 'wanderungen-in-schweizer-paerken',
    it: 'escursioni-nei-parchi-svizzeri',
  },
  'panoramic-hikes': {
    en: 'panoramic-hikes',
    fr: 'randonnees-avec-panorama',
    de: 'panorama-wanderungen',
    it: 'escursioni-panoramiche',
  },
  'hikes-near-the-water': {
    en: 'hikes-near-the-water',
    fr: 'randonnees-au-bord-de-l-eau',
    de: 'wanderungen-am-wasser',
    it: 'escursioni-in-riva-all-acqua',
  },
  'new-routes-of-2023-WL': {
    en: 'new-routes-of-2023-WL',
    fr: 'les-nouveaux-itineraires-de-2023-WL',
    de: 'neue-wanderland-routen-2023',
    it: 'i-nuovi-percorsi-del-2023-WL',
  },
  'e-bike-tours': {
    en: 'e-bike-tours',
    fr: 'tours-en-e-bike',
    de: 'touren-mit-dem-e-bike',
    it: 'gite-in-e-bike',
  },
  'touris-with-a-rented-bike': {
    en: 'touris-with-a-rented-bike',
    fr: 'tours-a-velo-de-location',
    de: 'touren-mit-dem-mietvelo',
    it: 'gite-con-bici-a-noleggio',
  },
  'family-cycling-tours': {
    en: 'family-cycling-tours',
    fr: 'tours-a-velo-pour-familles',
    de: 'familien-velotouren',
    it: 'gite-in-bicicletta-per-famiglie',
  },
  'cycling-tours-near-the-water': {
    en: 'cycling-tours-near-the-water',
    fr: 'tours-a-velo-au-bord-de-l-eau',
    de: 'velotouren-am-wasser',
    it: 'gite-in-bicicletta-in-riva-all-acqua',
  },
  'cycling-in-swiss-parks': {
    en: 'cycling-in-swiss-parks',
    fr: 'les-parcs-suisses-a-velo',
    de: 'velofahren-in-schweizer-paerken',
    it: 'gite-in-bici-nei-parchi-svizzeri',
  },
  'circular-cycling-tours': {
    en: 'circular-cycling-tour',
    fr: 'circuits-a-velo',
    de: 'rund-velotouren',
    it: 'gite-circolari-in-bici',
  },
  'new-routes-of-2023-VL': {
    en: 'new-routes-of-2023-V',
    fr: 'les-nouveaux-itineraires-de-2023-VL',
    de: 'neue-veloland-routen-2023',
    it: 'i-nuovi-percorsi-del-2023-VL',
  },
  'single-trail-tours': {
    en: 'single-trail-tours',
    fr: 'tours-a-vtt-par-des-sentiers',
    de: 'singletrail-touren',
    it: 'gite-su-singletrail',
  },
  'pleasure-tours-for-beginners': {
    en: 'pleasure-tours-for-beginner',
    fr: 'tours-a-vtt-pour-le-plaisir-debutants',
    de: 'genuss-touren-fuer-einsteiger',
    it: 'gite-di-piacere-per-principianti',
  },
  'mountainbiking-in-swiss-parks': {
    en: 'mountainbiking-in-swiss-parks',
    fr: 'mountainbiking-in-swiss-parks',
    de: 'mountainbiken-in-schweizer-paerken',
    it: 'gite-in-mountain-bike-nei-parchi-svizzeri',
  },
  'Tours-with-a-rented-mountainbike': {
    en: 'Tours-with-a-rented-mountainbik',
    fr: 'Tours-a-vtt-de-location',
    de: 'Touren-mit-dem-Miet-Mountainbike',
    it: 'Gite-con-mountain-bike-a-noleggio',
  },
  'tours-with-an-e-mountain-bike': {
    en: 'tours-with-an-e-mountain-bike',
    fr: 'tours-a-vtt-electrique',
    de: 'touren-mit-dem-e-mountainbike',
    it: 'gite-con-la-e-mountainbike',
  },
  'new-routes-of-2023-ML': {
    en: 'new-routes-of-2023-ML',
    fr: 'les-nouveaux-itineraires-de-2023-ML',
    de: 'neue-mountainbike-routen-2023',
    it: 'i-nuovi-percorsi-del-2023-ML',
  },
  'winter-hiking-in-swiss-parks': {
    en: 'winter-hiking-in-swiss-park',
    fr: 'randonnees-hivernales-dans-les-parcs-suisse',
    de: 'winterwanderungen-in-schweizer-paerken',
    it: 'escursioni-invernali-nei-parchi-svizzeri',
  },
  'the-new-paths-of-2022-2023': {
    en: 'the-new-paths-of-2022-202',
    fr: 'les-nouveaux-chemins-de-2022-2023',
    de: 'neue-winterwanderwege-2022-2023',
    it: 'i-nuovi-sentieri-di-2022-2023',
  },
  'winter-hiking-loop-trails': {
    en: 'winter-hiking-loop-trail',
    fr: 'circuits-de-randonnee-hivernale',
    de: 'rund-winterwanderwege',
    it: 'sentieri-escursionistici-invernali-circolari',
  },
  'winter-hikes-with-children': {
    en: 'winter-hikes-with-children',
    fr: 'randonnees-hivernales-avec-enfants',
    de: 'winterwanderungen-mit-kindern',
    it: 'escursioni-invernali-con-bambini',
  },
  'snowshoe-trails-in-swiss-parks': {
    en: 'snowshoe-trails-in-swiss-parks',
    fr: 'randonnees-en-raquettes-dans-les-parcs-suisses',
    de: 'schneeschuhtrails-in-schweizer-paerken',
    it: 'ciaspolate-nei-parchi-svizzeri',
  },
  'snowshoe-trails-with-summit-experience': {
    en: 'snowshoe-trails-with-summit-experience',
    fr: 'randonnees-en-raquettes-jusqu-a-un-sommet',
    de: 'schneeschuhtrails-mit-gipfelerlebnis',
    it: 'emozionanti-ciaspolate-fino-in-vetta',
  },
  'the-new-snowshoe-trails-of-2022-2023-1': {
    en: 'the-new-snowshoe-trails-of-2022-2023-1',
    fr: 'les-nouvelles-randonnees-en-raquettes-2022-2023',
    de: 'neue-schneeschuhtrails-2022-2023',
    it: 'le-nuove-ciaspolate-di-2022-2023',
  },
  'snowshoe-loop-trails': {
    en: 'snowshoe-loop-trails',
    fr: 'circuits-de-randonnee-en-raquettes',
    de: 'rund-schneeschutrails',
    it: 'ciaspolate-circolari',
  },
  'snowshoe-hikes-with-children': {
    en: 'snowshoe-hikes-with-children',
    fr: 'randonnees-en-raquettes-avec-enfants',
    de: 'schneeschuhwanderungen-mit-kindern',
    it: 'ciaspolate-con-bambini',
  },
  'cross-country-ski-trail-in-swiss-parks-2': {
    en: 'cross-country-ski-trail-in-swiss-parks-2',
    fr: 'pistes-de-ski-de-fond-dans-les-parcs-suisses',
    de: 'loipen-in-schweizer-paerken',
    it: 'piste-di-fondo-nei-parchi-svizzeri',
  },
  'the-new-trails-of-2022-2023': {
    en: 'the-new-trails-of-2022-2023',
    fr: 'les-nouvelles-pistes-de-2022-2023',
    de: 'neue-langlauf-loipen-2022-202',
    it: 'le-nuove-piste-di-2022-2023-1',
  },
  'sledging-in-swiss-parks': {
    en: 'sledging-in-swiss-parks',
    fr: 'luge-dans-les-parcs-suisse',
    de: 'schlittelwege-in-schweizer-paerken',
    it: 'slittino-nei-parchi-svizzeri',
  },
  'night-sledging': {
    en: 'night-sledging',
    fr: 'luge-nocturne',
    de: 'nachtschlitteln',
    it: 'slittino-notturno',
  },
};
