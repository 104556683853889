import {msg} from '@lit/localize';
import type {ModalOptions} from '../stores/store-main.ts';
import type {ScreenSizeCode} from './elements.ts';
import type {SeasonCode} from '../layers/layerTypes.ts';
import type {LanguageCode} from '../i18n.ts';

export const SEASON_SWITCH_ITEMS: {label: () => string; value: SeasonCode}[] = [
  {label: () => msg('Summer'), value: 'summer'},
  {label: () => msg('Winter'), value: 'winter'},
];

export const IntegerFormat = new Intl.NumberFormat('de-CH', {
  maximumFractionDigits: 2,
});

const POIOverlayTypes = ['sightseeing', 'place', 'travelreport', 'details'];
export const isPOIOverlay = (options: ModalOptions) =>
  options && POIOverlayTypes.includes(options.type);

// swiss extent in EPSG:2056 (from https://epsg.io/2056)
export const SWISS_EXTENT = [2485071, 1074261, 2837119, 1299941];
export const SWISS_CENTER = [2660000, 1190000];

// Geoadmin objectclasses to exclude from search results
export const EXCLUDED_SEARCH_RESULTS = [
  'TLM_SCHIFFFAHRT',
  'TLM_EISENBAHN',
  'TLM_UEBRIGE_BAHN',
  'TLM_STRASSE',
  'TLM_MAUER',
  'TLM_VERBAUUNG',
  'TLM_VERKEHRSBAUTE_LIN',
  'TLM_LEITUNG',
  'TLM_LEITUNG_STROMSTRASSE',
  'TLM_STRASSENROUTE',
  'TLM_HALTESTELLE',
  'TLM_AUS_EINFAHRT',
  'TLM_STRASSENINFO',
  'TLM_STRASSENNAME',
  'TLM_VERSORGUNGSBAUTE_PKT',
  'TLM_SPORTBAUTE_PLY',
  'TLM_GEBAEUDE_FOOTPRINT',
  'TLM_BODENBEDECKUNG',
  'TLM_EINZELBAUM_GEBUESCH',
  'TLM_BLAMOS',
  'TLM_VERKEHRSBAUTE',
  'TLM_NUTZUNGSAREAL',
  'TLM_STAUBAUTE',
  'TLM_SCHUTZGEBIET',
  'TLM_SCHULE',
  'MORPH_KLEINFORM_PKT',
];

export const SCREENS: Record<ScreenSizeCode, number> = {
  zero: 0,
  xxs: 360,
  xs: 428,
  sm: 600,
  md: 820,
  mdx: 1024,
  mdxx: 1194,
  lg: 1239,
  xl: 1440,
  xxl: 1681,
  max: 2184,
};

export const SBB_URL_BY_LANG: Record<LanguageCode, string> = {
  de: 'https://www.sbb.ch/de/kaufen/pages/fahrplan/fahrplan.xhtml',
  en: 'https://www.sbb.ch/en/buying/pages/fahrplan/fahrplan.xhtml',
  fr: 'https://www.sbb.ch/fr/acheter/pages/fahrplan/fahrplan.xhtml',
  it: 'https://www.sbb.ch/it/acquistare/pages/fahrplan/fahrplan.xhtml',
};
