/**
 * Capitalizes a string, i.e. converts the first letter to uppercase
 * and all other letters to lowercase, e.g.:
 *
 * @param {string} str String value to capitalize.
 * @return {string} String value with first letter in uppercase.
 */
export function capitalize(str: string) {
  return (
    String(str.charAt(0)).toUpperCase() + String(str.substring(1)).toLowerCase()
  );
}

/**
 * Check is bigger box include smaller
 *
 * @param biggerBox
 * @param smallBox
 */
export function includeBbox(biggerBox: number[], smallBox: number[]) {
  const lowerPoints = biggerBox.filter((b, key) => b <= smallBox[key]);
  const highestPoints = biggerBox.filter((b, key) => b >= smallBox[key]);
  return lowerPoints.length === 2 && highestPoints.length === 2;
}

export function equalObject(
  obj1: Record<string, any>,
  obj2: Record<string, any>,
) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];
    if (isObject(val1) && isObject(val2)) {
      if (!equalObject(val1, val2)) {
        return false;
      }
    } else {
      if (val1 !== val2) {
        return false;
      }
    }
  }
  return true;
}

function isObject(obj: any): boolean {
  return obj !== null && typeof obj === 'object';
}

/**
 * Checks if the current date is between two other dates. Only DD.MM is taken into account.
 * @param startDate
 * @param endDate
 * @param currentDate
 */
export function isDateBetween(
  startDate: Date,
  endDate: Date,
  currentDate: Date,
) {
  const year = currentDate.getFullYear();
  startDate.setFullYear(year);
  endDate.setFullYear(year);
  const nowMillis = currentDate.getTime();

  return nowMillis >= startDate.getTime() && nowMillis <= endDate.getTime();
}

export const throttle = (callback: () => void, delay: number) => {
  let timer: NodeJS.Timeout | undefined;
  return () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => callback(), delay);
  };
};
