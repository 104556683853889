import {BehaviorSubject} from 'rxjs';
import type {
  DetailedRecordedTour,
  DetailedTour,
  TourListItem,
  TourPrefs,
  TourType,
} from '../apis/api-tours.ts';
import {
  DEFAULT_MOUNTAIN_BIKE_SPEED,
  TourColors,
  DEFAULT_BIKE_SPEED,
} from '../apis/api-tours.ts';
import type {UserSettings} from '../apis/api-users.ts';

export interface TourStyleData {
  colorRgb: string;
  opacity: number;
}
export const tourStyleData$ = new BehaviorSubject<TourStyleData>({
  colorRgb: '#dddddd',
  opacity: 100,
});

export const tours$ = new BehaviorSubject<TourListItem[]>(undefined);

/**
 * The tour type of the currently active tour, if any.
 */
export const currentlyActiveTourType$ = new BehaviorSubject<
  TourType | undefined
>(undefined);

/**
 * The id of the currenly active track.
 */
export const activeTrackId$ = new BehaviorSubject<number | undefined>(
  undefined,
);

/**
 * The number of POIS of the currenly active track.
 */
export const activeTrackPoisCount$ = new BehaviorSubject<number>(0);

/**
 * The bike speed of the currently active tour, if any.
 * That is the stored bike speed, not the resolved one.
 */
export const currentlyActiveTourBikeSpeed$ = new BehaviorSubject<
  number | undefined
>(undefined);

/**
 * The mountain bike speed of the currently active tour, if any.
 * That is the stored mountain bike speed, not the resolved one.
 */
export const currentlyActiveTourMountainBikeSpeed$ = new BehaviorSubject<
  number | undefined
>(undefined);

/**
 * The hike speed of the currently active tour, if any.
 * That is the stored hike speed, not the resolved one.
 */
export const currentlyActiveTourHikeSpeed$ = new BehaviorSubject<
  number | undefined
>(undefined);

/**
 * The winter hike speed of the currently active tour, if any.
 * That is the stored winter hike speed, not the resolved one.
 */
export const currentlyActiveTourWinterhikeSpeed$ = new BehaviorSubject<
  number | undefined
>(undefined);

/**
 * The snowshoe speed of the currently active tour, if any.
 * That is the stored snowshoe speed, not the resolved one.
 */
export const currentlyActiveTourSnowshoeSpeed$ = new BehaviorSubject<
  number | undefined
>(undefined);

import {userSettings$} from './store-user.ts';

export function resolveBikeSpeed(): number {
  return determineSpeed(
    DEFAULT_BIKE_SPEED,
    userSettings$.getValue()?.bicycleSpeed,
    currentlyActiveTourBikeSpeed$.getValue(),
  );
}

export function resolveMountainBikeSpeed(): number {
  return determineSpeed(
    DEFAULT_MOUNTAIN_BIKE_SPEED,
    userSettings$.getValue()?.mountainBikingSpeed,
    currentlyActiveTourMountainBikeSpeed$.getValue(),
  );
}

export function resolveHikeSpeed(): number {
  return determineSpeed(
    1.0,
    userSettings$.getValue()?.hikeSpeed,
    currentlyActiveTourHikeSpeed$.getValue(),
  );
}

export function resolveWinterhikeSpeed(): number {
  return determineSpeed(
    1.0,
    userSettings$.getValue()?.winterhikeSpeed,
    currentlyActiveTourWinterhikeSpeed$.getValue(),
  );
}

export function resolveSnowshoeSpeed(): number {
  return determineSpeed(
    1.0,
    userSettings$.getValue()?.snowshoeSpeed,
    currentlyActiveTourSnowshoeSpeed$.getValue(),
  );
}

export function determineSpeed(
  defaultSpeed: number,
  userSettingsSpeed: number | undefined,
  tourSpeed: number | undefined,
): number {
  return tourSpeed ?? userSettingsSpeed ?? defaultSpeed;
}

// For some reason typescript wants the return type to include DetailedRecordedTour.
export function createEmptyTour(
  userSettings: UserSettings,
): DetailedTour | DetailedRecordedTour {
  const pref: TourPrefs = {
    color: userSettings.color,
    colorRgb: TourColors[userSettings.color],
    gpspoints: userSettings.gpsPoints,
    opacity: userSettings.opacity,
    timetype: userSettings.timetype || 'wander',
    bikespeed: userSettings.bicycleSpeed,
    hikespeed: userSettings.hikeSpeed || 1,
    winterhikespeed: userSettings.winterhikeSpeed || 1,
    snowshoespeed: userSettings.snowshoeSpeed || 1,
    mountainbikespeed:
      userSettings.mountainBikingSpeed || DEFAULT_MOUNTAIN_BIKE_SPEED,
  };

  const newTour: DetailedTour = {
    properties: {
      name: '',
      type: 'normal',
      public: false,
      meta: {
        elemin: 0,
        elemax: 0,
        length: 0,
        totalup: 0,
        totaldown: 0,
        walking: 0,
        biking: 0,
        winterHiking: 0,
        snowshoeTrekking: 0,
      },
      pref: pref,
      filter: null,
      // date format for datepicker
      userdate: new Date().toISOString().split('T')[0],
    },
    geom: [[]],
    pois: [],
    viapointsIndices: [[]],
  };
  return newTour;
}
