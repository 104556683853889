import type {
  LandCategoryUrlParam,
  LandCode,
  LandLayers,
  LegacyUserLand,
  POILayerInfo,
  RouteCategoryCode,
  RouteLayerInfo,
  SeasonCode,
  SummerLandLayers,
  WinterLandCode,
  WinterLandLayers,
  LayerCode,
  AdditionalLayertreeCode,
  LayertreeCode,
  AdditionalLayerType,
  MultiLandLayers,
  AlternativeLandCode,
} from './layerTypes.ts';
import type {BaseLayerCode} from './layerConstants.ts';
import {
  ADDITIONAL_LAYER_TYPES,
  ADDITIONAL_LAYERTREE_CODES,
  alternativeLandCodes,
  baseLayerCodes,
  LAND_CATEGORIES,
  landCategoryUrlParams,
  legacyLandCodes,
  multiLandLayers,
  SEASON_CODES,
  summerLandLayers,
  winterLandCodes,
  winterLandLayers,
} from './layerConstants.ts';
import {capitalize} from '../utils.ts';

export function getMultiLandCategoryKVP(
  land: LandLayers,
  category: RouteCategoryCode | 'wanderwegnetz' | 'crosscountrynetwork',
): LandCategoryUrlParam | undefined {
  if (land === 'wanderland') {
    switch (category) {
      case 'national':
        return 'wanderlandetappennational';
      case 'regional':
        return 'wanderlandetappenregional';
      case 'local':
        return 'wanderlandetappenlokal';
      case 'handicap':
        return 'wanderlandetappenhandicap';
      case 'wanderwegnetz':
        return 'wanderwegnetz';
    }
  } else if (land === 'veloland') {
    switch (category) {
      case 'national':
        return 'velolandetappennational';
      case 'regional':
        return 'velolandetappenregional';
      case 'local':
        return 'velolandetappenlokal';
    }
  } else if (land === 'mtbland') {
    switch (category) {
      case 'national':
        return 'mtblandetappennational';
      case 'regional':
        return 'mtblandetappenregional';
      case 'local':
        return 'mtblandetappenlokal';
    }
  } else if (land === 'skatingland') {
    switch (category) {
      case 'national':
        return 'skatinglandetappennational';
      case 'regional':
        return 'skatinglandetappenregional';
      case 'local':
        return 'skatinglandetappenlokal';
    }
  } else if (land === 'kanuland') {
    switch (category) {
      case 'national':
        return 'kanulandetappennational';
      case 'regional':
        return 'kanulandetappenregional';
    }
  } else if (land === 'crosscountry') {
    switch (category) {
      case 'local':
        return 'crosscountryetappenlokal';
      case 'crosscountrynetwork':
        return 'crosscountrynetwork';
    }
  }
  return undefined;
}

export function isRouteLayer(
  layer: RouteLayerInfo | POILayerInfo,
): layer is RouteLayerInfo {
  layer = layer as RouteLayerInfo;
  return !!(layer.landCode && layer.layer && layer.legacyLandCode);
}

export function seasonFromLandCode(landCode: LandCode): SeasonCode {
  return isWinterLandCode(landCode) ? 'winter' : 'summer';
}

export function isWinterLandCode(code: LandCode): code is WinterLandCode {
  return winterLandCodes.includes(code as WinterLandCode);
}

export function isSummerLayer(layer: string): layer is SummerLandLayers {
  return summerLandLayers.includes(layer as SummerLandLayers);
}

export function isMultiLayer(layer: string): layer is MultiLandLayers {
  return multiLandLayers.includes(layer as MultiLandLayers);
}

export function isWinterLayer(layer: string): layer is WinterLandLayers {
  return winterLandLayers.includes(layer as WinterLandLayers);
}

export function isSeasonCode(season: string): season is SeasonCode {
  return SEASON_CODES.includes(season as SeasonCode);
}

export function isBgCode(bgCode: string): bgCode is BaseLayerCode {
  return baseLayerCodes.includes(bgCode as BaseLayerCode);
}

export function isLandCategoryUrlPrams(
  code: string,
): code is (typeof landCategoryUrlParams)[number] {
  return landCategoryUrlParams.includes(
    code as (typeof landCategoryUrlParams)[number],
  );
}

export function isAdditionalLayertreeCode(
  code: string,
): code is AdditionalLayertreeCode {
  return ADDITIONAL_LAYERTREE_CODES.includes(code as AdditionalLayertreeCode);
}

export function isLayertreeCode(code: string): code is LayertreeCode {
  return (
    isSummerLayer(code) ||
    isWinterLayer(code) ||
    isLandCategoryUrlPrams(code) ||
    isAdditionalLayertreeCode(code)
  );
}

export function isLandLayer(
  code: string,
): code is SummerLandLayers | WinterLandLayers {
  return isSummerLayer(code) || isWinterLayer(code);
}

export function isAdditionalLayerType(
  type: string,
): type is AdditionalLayerType {
  return ADDITIONAL_LAYER_TYPES.includes(type as AdditionalLayerType);
}

export function isAlternativeLandCode(
  code: string,
): code is AlternativeLandCode {
  return alternativeLandCodes.includes(code as AlternativeLandCode);
}

export function isLegacyUserLand(code: string): code is LegacyUserLand {
  return legacyLandCodes.includes(code as LegacyUserLand);
}

export function getLowercaseLandCategories(
  landLayer: MultiLandLayers,
): LandCategoryUrlParam[] {
  return LAND_CATEGORIES[landLayer]
    .map((c: string) => {
      const code = c.toLowerCase();
      return isLandCategoryUrlPrams(code) ? code : undefined;
    })
    .filter((c) => !!c);
}

export function getLayerApiCode(code: LayerCode) {
  if (isMultiLayer(code)) {
    const categories: string[] = LAND_CATEGORIES[code];
    return categories.join(',');
  }
  return code === 'alpinetaxi' ? 'Alpentaxi' : capitalize(code);
}

export function getLayerTreeCodes(codes: string[]) {
  return codes
    .map((c) => {
      const lowercaseCode = c.toLowerCase();
      return lowercaseCode;
    })
    .filter((c) => isLayertreeCode(c)) as LayertreeCode[];
}

export const apiCodeToLandCode: Record<LandLayers, LandCode> = {
  wanderland: 'hike',
  veloland: 'cycle',
  mtbland: 'mountainbike',
  skatingland: 'skate',
  kanuland: 'canoe',
  winterhiking: 'winterhike',
  snowshoe: 'snowshoe',
  crosscountry: 'crosscountry',
  sledging: 'sledge',
};

export const landCodeToApiCode: Record<LandCode, LandLayers> = {
  hike: 'wanderland',
  cycle: 'veloland',
  mountainbike: 'mtbland',
  skate: 'skatingland',
  canoe: 'kanuland',
  winterhike: 'winterhiking',
  snowshoe: 'snowshoe',
  crosscountry: 'crosscountry',
  sledge: 'sledging',
};

export const landToLayer: Record<LegacyUserLand, LandLayers> = {
  wander: 'wanderland',
  velo: 'veloland',
  mountainbike: 'mtbland',
  skating: 'skatingland',
  kanu: 'kanuland',
  winterhiking: 'winterhiking',
  snowshoe: 'snowshoe',
  crosscountry: 'crosscountry',
  sledging: 'sledging',
};

export const landToLandCode: Record<LegacyUserLand, LandCode> = {
  wander: 'hike',
  velo: 'cycle',
  mountainbike: 'mountainbike',
  skating: 'skate',
  kanu: 'canoe',
  winterhiking: 'winterhike',
  snowshoe: 'snowshoe',
  crosscountry: 'crosscountry',
  sledging: 'sledge',
};

export const alternativeCodeToLandCode: Record<AlternativeLandCode, LandCode> =
  {
    mountainbikeland: 'mountainbike',
    mtb: 'mountainbike',
  };
