export function resolveDeployPrefix(): string {
  // Check for SSR
  if (typeof document === 'undefined') {
    return '';
  }
  const l = document.location;
  if (l.pathname.startsWith('/reviews')) {
    // ex: /reviews/nice_looking_teasers_GSCHM-2931/...
    return l.pathname.split('/').splice(0, 3).join('/');
  }
  return '';
}
