/**
 * This file is for the code relative to internationalization.
 */
import {configureTransformLocalization} from '@lit/localize';

export const b2bLanguageCodes = ['de', 'fr', 'it'] as const;
export const languageCodes = ['de', 'fr', 'it', 'en'] as const;
export type LanguageCode = (typeof languageCodes)[number];
export type B2bLanguageCode = (typeof b2bLanguageCodes)[number];

const {getLocale} = configureTransformLocalization({
  sourceLocale: 'en',
});
export function getLanguageCode(): LanguageCode {
  if (
    ['localhost', 'bs-local.com'].includes(document.location.hostname) &&
    document.location.port !== '8000'
  ) {
    const pathSplited = location.pathname
      .split('/')
      .filter((part) => part !== '');
    const lang: LanguageCode = pathSplited[0] as LanguageCode;
    if (!languageCodes.includes(lang)) return 'en';
    return lang;
  }
  const code = getLocale();
  // The language code for German is 'de-ch' in the xliff file but we want to use 'de'.
  return code === 'de-ch' ? 'de' : (code as LanguageCode);
}
