import {BehaviorSubject, Subject} from 'rxjs';
import type {Place} from '../apis/api-places.ts';
import type {Sightseeing} from '../apis/api-sightseeings.ts';
import type {AdditionalLayerData} from '../apis/api-poi.ts';
import type {Gallery, FeedbackType} from '../apis/api-common.ts';
import type {Travelreport} from '../apis/api-travelreports.ts';
import type {
  DetailedRouteOrSegment,
  RouteSegmentGallery,
} from '../apis/api-segments.ts';
import type {SeasonCode} from '../layers/layerTypes.ts';
import type {LanguageCode} from '../i18n.ts';
import type {HTMLTemplateResult} from 'lit';
import {USER_ROLE} from '../helpers/helper-users.ts';
import type ElementBottomSheet from '../elements/element-bottom-sheet.ts';

export type POIMeta = {
  title: string;
  description: string;
  photo: string;
  photoValidity: 'temp' | 'valid';
};

export type MaintenanceStatus = {
  logindisabled: boolean;
  showloginmsg: boolean;
  loginmsgde?: string;
  loginmsgfr?: string;
  loginmsgit?: string;
  loginmsgen?: string;
  maintenance_from?: string;
  maintenance_to?: string;
};

export type MapPOIModalOptions =
  | {type: 'sightseeing'; data: Sightseeing}
  | {type: 'place'; data: Place}
  | {type: 'details'; data: AdditionalLayerData};
export type POIModalOptions =
  | MapPOIModalOptions
  | {
      type: 'travelreport';
      data: {travelreport: Travelreport; segment?: DetailedRouteOrSegment};
    };

export function isMapPOIModalOptions(
  options: ModalOptions,
): options is MapPOIModalOptions {
  return (
    options.type === 'sightseeing' ||
    options.type === 'place' ||
    options.type === 'details'
  );
}

export type ModalOptions =
  | {type: 'filter'}
  | {type: 'share'}
  | {type: 'layers'}
  | {type: 'goToOldMap'}
  | {type: 'contact'; data?: {feedbackType: FeedbackType}}
  | {type: 'goToOldMapOrLogin'}
  | {type: 'sharedTourNotification'}
  | {type: 'gallery'; data: {photos: Gallery[]; index: number}}
  | POIModalOptions
  | {type: 'staticPage'; value: string}
  | {type: 'importGpx'}
  | {
      type: 'confirm';
      data: {
        title?: string;
        text?: string;
        confirmBtnText?: string;
        cancelBtnText?: string;
        onConfirm?: () => void;
        onCancel?: () => void;
        onClose?: () => void;
        id?: string;
      };
    };
// To add a new modal option, add a new type to ModalOptions and adapt the overlay renderContentFactory() method in newui/src/elements/overlays/element-overlay.ts
export const modal$ = new BehaviorSubject<ModalOptions | undefined>(undefined);

export type SwipeState =
  | 'open'
  | 'close'
  | 'quarter'
  | 'half'
  | 'threeQuarters';

export type BottomSheetOptions = {
  data: {
    state: SwipeState;
    greyBg?: boolean;
    showCloseBtn?: boolean;
    darkSurround?: boolean;
    swipeStates?: SwipeState[];
  };
  element:
    | HTMLTemplateResult
    | ((bottomSheetElement: ElementBottomSheet) => HTMLTemplateResult);
  guardElementRender?: boolean;
  guardDependencies?: any[];
};

export const bottomSheet$ = new BehaviorSubject<BottomSheetOptions | undefined>(
  undefined,
);

export type ScrollPositionSubject = {
  land: string;
  category: string;
  position: number;
  maxDisplayed: number;
};

// correct season will be set in app-index
export const season$ = new BehaviorSubject<SeasonCode>('summer');

export const disableKvp$ = new BehaviorSubject<boolean>(true);

export const filterDirty$ = new BehaviorSubject<boolean>(false);

export const maintenanceStatus$ = new BehaviorSubject<MaintenanceStatus>({
  logindisabled: false,
  showloginmsg: false,
});

export const userStatus$ = new BehaviorSubject<USER_ROLE>(USER_ROLE.notLogged);

export const updateChosenFilters$ = new Subject<boolean>();

export const urlSearchParamsChanged$ = new BehaviorSubject<URLSearchParams>(
  new URLSearchParams(),
);

export const bigGalleryIndexChange$ = new Subject<number>();

export const disableMapAnimation$ = new BehaviorSubject<boolean>(false);

export const disableMainPageAnimation$ = new BehaviorSubject<boolean>(false);

export const adminMessageShown$ = new BehaviorSubject<boolean>(false);

export const adminMessageLoaded$ = new BehaviorSubject<boolean>(false);

export const rememberListView$ = new BehaviorSubject<boolean>(false);
export const rememberFilterView$ = new BehaviorSubject<boolean>(false);

export const rememberScrollPosition$ =
  new BehaviorSubject<ScrollPositionSubject>(undefined);

export const stpShortTitles$ = new BehaviorSubject<
  Record<LanguageCode, string> | undefined
>(undefined);

export const activeFocusGallery$ = new BehaviorSubject<
  RouteSegmentGallery[] | undefined
>(undefined);
