import {fetchUserDetails, fetchUserSettings} from '../apis/api-users.ts';
import {userStatus$} from '../stores/store-main.ts';
import {userSettings$, user$} from '../stores/store-user.ts';
import {USER_ROLE} from './helper-users.ts';
import {logoutFromKeycloak, oidcClient} from './oidcClient.ts';

export interface JWTPayload {
  roles: string[];
  email: string;
}

export async function initializeUserWithToken() {
  try {
    const token = await oidcClient.getActiveToken();
    if (!token || token === '') {
      return;
    }
    const parsedToken = oidcClient.parseJwtPayload(
      token,
    ) as unknown as JWTPayload;
    if (parsedToken) {
      const userDetails = await fetchUserDetails();
      user$.next({
        email: parsedToken.email,
        firstName: userDetails?.firstName,
      });
      setUserStatus(parsedToken);
    }
    const userSettings = await fetchUserSettings();
    userSettings$.next(userSettings);
  } catch (e) {
    console.error('could not initialize user', e);
  }
}

export function setUserStatus(token: JWTPayload | undefined): void {
  if (!token) {
    userStatus$.next(USER_ROLE.notLogged);
    user$.next(null);
    return;
  }

  let role = USER_ROLE.subscribedUser;
  if (!token.roles?.includes(USER_ROLE.subscribedUser)) {
    if (token.roles?.includes(USER_ROLE.subscriptionRenewal))
      role = USER_ROLE.subscriptionRenewal;
    else if (token.roles?.includes(USER_ROLE.subscriptionPending)) {
      role = USER_ROLE.subscriptionRenewal;
    } else role = USER_ROLE.noRole;
  }
  userStatus$.next(role);
}

export async function login(): Promise<void> {
  localStorage.setItem('appPreLoginURL', document.location.href);
  try {
    const loginURL = await oidcClient.createAuthorizeAndUpdateLocalStorage([
      'openid',
      'roles',
    ]);
    document.location = loginURL;
  } catch (error) {
    console.error('Error:', error);
  }
}

export async function logout() {
  const idToken = localStorage.getItem('oidcjs_id_token');
  oidcClient.lclear();
  // we will reload the page during the logout process so we don't have to clear the user status
  // in addition, that would interfere with the logout process
  logoutFromKeycloak(idToken);
}
