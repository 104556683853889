import {BehaviorSubject, Subject} from 'rxjs';
import type {User, UserSettings} from '../apis/api-users.ts';

/**
 * This stores the active user data.
 */
export const user$ = new BehaviorSubject<User>(null);
/**
 * This stores the active user data.
 */
export const userSettings$ = new BehaviorSubject<UserSettings>(null);

export const sseRefresh$ = new Subject<void>();
