import {BehaviorSubject, Subject} from 'rxjs';
import {
  isDetoursEnabled,
  getLayersForLayertree,
  isLogosEnabled,
  isPhotosEnabled,
  isShootingEnabled,
} from '../urlHandling/urlSearchParams.ts';
import type Map from 'ol/Map';
import type {
  AdditionalRouteLayerCode,
  LayertreeCode,
} from '../layers/layerTypes.ts';
import type {BaseLayerCode} from '../layers/layerConstants.ts';
import type {RecordedTourMeta, TourMeta} from '../apis/api-tours.ts';
import type {GeolocationHelper} from '../map/geolocationHelper.ts';

export const meta$ = new BehaviorSubject<
  TourMeta | RecordedTourMeta | undefined
>(undefined);

export const map$ = new BehaviorSubject<Map | undefined>(undefined);
export const geolocationHelper$ = new BehaviorSubject<
  GeolocationHelper | undefined
>(undefined);
export const mapElementConnected$ = new BehaviorSubject<boolean>(false);
export const bgLayer$ = new BehaviorSubject<BaseLayerCode>('pk');
export const edittrackMode$ = new BehaviorSubject<'edit' | ''>('');

export const mapClick$ = new Subject<number[]>();
export const showCoordinates$ = new Subject<number[] | undefined>();

export const visibleLayers$ = new BehaviorSubject<LayertreeCode[]>(
  getLayersForLayertree(),
);

export const visibleAdditionalRouteLayers$ = new BehaviorSubject<
  Record<AdditionalRouteLayerCode, boolean>
>({
  detours: isDetoursEnabled(),
  photos: isPhotosEnabled(),
  logos: isLogosEnabled(),
  shooting: isShootingEnabled(),
});

export const isMapPage$ = new BehaviorSubject<boolean>(false);

export const showPlaceFromSearch$ = new BehaviorSubject<
  {pointCoordinates: number[]} | undefined
>(undefined);
