import type {
  RouteFitnessLevel,
  RouteGradeSkiing,
  RouteOrSegmentFilters,
} from '../apis/api-segments.ts';
import {msg} from '@lit/localize';
import {equalObject} from '../utils.ts';
import type {LandCode, SeasonCode} from '../layers/layerTypes.ts';
import {season$} from '../stores/store-main.ts';

export const GRADE_FITNESS_LEVELS: Record<RouteFitnessLevel, boolean> = {
  easy: true,
  medium: true,
  difficult: true,
};

export const GRADE_SKIING: Record<RouteGradeSkiing, boolean> = {
  classic: true,
  skating: true,
  both: true,
};

export const HANDICAP_SWITCH_ITEMS: {
  label: () => string;
  value: 'routes' | 'handicap';
}[] = [
  {label: () => msg('Routes'), value: 'routes'},
  {label: () => msg('Obstacle-free routes'), value: 'handicap'},
];

export const MOUNTAINBIKE_SWITCH_ITEMS: {
  label: () => string;
  value: 'routes' | 'structures';
}[] = [
  {label: () => msg('Routes'), value: 'routes'},
  {label: () => msg('Mountain bike facilities'), value: 'structures'},
];

export const DEFAULT_LAND_CODES: Record<SeasonCode, LandCode> = {
  winter: 'winterhike',
  summer: 'hike',
};

export const STRUCTURES_GROUP_NUMBER = 1;

export function defaultFilterValues(): RouteOrSegmentFilters {
  const season = season$.value;
  return {
    season: season,
    land: DEFAULT_LAND_CODES[season],
    categories: {national: true, regional: true, local: true, handicap: false},
    grades: {...GRADE_FITNESS_LEVELS},
    gradesSkiing: {...GRADE_SKIING},
    fitness: {...GRADE_FITNESS_LEVELS},
    canton: 0,
    specialInterests: {
      day: false,
      bookable: false,
      groups: {
        canoe: [],
        crosscountry: [],
        cycle: [],
        hike: [],
        mountainbike: [],
        skate: [],
        sledge: [],
        snowshoe: [],
        winterhike: [],
      },
    },
    structures: false,
  };
}

export function hasGrade(filter: RouteOrSegmentFilters): boolean {
  return (
    filter.land !== 'cycle' &&
    filter.land !== 'winterhike' &&
    filter.land !== 'snowshoe' &&
    filter.land !== 'crosscountry' &&
    !filter.structures
  );
}

export function hasFitness(filter: RouteOrSegmentFilters): boolean {
  return filter.land !== 'sledge' && !filter.structures;
}

export function hasCategories(filter: RouteOrSegmentFilters): boolean {
  return (
    !filter.structures &&
    !filter.categories.handicap &&
    filter.season !== 'winter'
  );
}

export function hasFitnessGrade(filter: RouteOrSegmentFilters) {
  return (
    hasGrade(filter) || hasFitness(filter) || filter.land === 'crosscountry'
  );
}

// true if the filter was changed by the user
export function isFilterDirty(filter: RouteOrSegmentFilters): boolean {
  const defaultValues = defaultFilterValues();

  const specialInterestsGroups = Object.values(filter.specialInterests.groups);
  // true if one of the special interests groups is checked
  const specialInterestsGroupsIsSet = specialInterestsGroups.some((group) =>
    group.some((v) => v.checked),
  );
  return (
    defaultValues.land !== filter.land ||
    defaultValues.season !== filter.season ||
    !equalObject(defaultValues.categories, filter.categories) ||
    !equalObject(defaultValues.grades, filter.grades) ||
    !equalObject(defaultValues.gradesSkiing, filter.gradesSkiing) ||
    !equalObject(defaultValues.fitness, filter.fitness) ||
    defaultValues.canton !== filter.canton ||
    defaultValues.specialInterests.day !== filter.specialInterests.day ||
    defaultValues.specialInterests.bookable !==
      filter.specialInterests.bookable ||
    specialInterestsGroupsIsSet
  );
}
