import {BASE_API_URL} from '../envs.ts';
import type {Coordinate} from 'ol/coordinate';
import type {
  Point as GeoJSONPoint,
  FeatureCollection,
  Feature as GeoJSONFeature,
} from 'geojson';
import {fetchUserCheck, type UserSettings} from './api-users.ts';

export const DEFAULT_TOUR_COLOR = 'red';
export const DEFAULT_BIKE_SPEED = 20;
export const DEFAULT_MOUNTAIN_BIKE_SPEED = 17;
export const DEFAULT_HIKE_SPEED = 1.0;
export const DEFAULT_WINTERHIKE_SPEED = 1.0;
export const DEFAULT_SNOWSHOE_SPEED = 1.0;
export const DEFAULT_TOUR_GPS_POINTS = 1000;
export const DEFAULT_TOUR_OPACITY = 100;
export const DEFAULT_TOUR_TIMETYPE = 'wander';

export const DEFAULT_USER_SETTINGS: UserSettings = Object.freeze({
  color: DEFAULT_TOUR_COLOR,
  bicycleSpeed: DEFAULT_BIKE_SPEED,
  hikeSpeed: DEFAULT_HIKE_SPEED,
  winterhikeSpeed: DEFAULT_WINTERHIKE_SPEED,
  snowshoeSpeed: DEFAULT_SNOWSHOE_SPEED,
  mountainBikingSpeed: DEFAULT_MOUNTAIN_BIKE_SPEED,
  filterId: -1,
  gpsPoints: DEFAULT_TOUR_GPS_POINTS,
  opacity: DEFAULT_TOUR_OPACITY,
  public: true,
  timetype: DEFAULT_TOUR_TIMETYPE,
});
import type Feature from 'ol/Feature';
import {oidcClient} from '../helpers/oidcClient.ts';
import {setUserStatus} from '../helpers/keycloakUser.ts';
import type {JWTPayload} from '../helpers/keycloakUser.ts';
import {USER_ROLE} from '../helpers/helper-users.ts';
import {hasAuthCookie} from '../helpers/tokenOrCookie.ts';
import {placeholderDateNow} from '../helpers/tours.ts';

async function authFetchUsingToken(
  input: RequestInfo | URL,
  init: RequestInit,
  token: string,
) {
  if (!init.headers) {
    init.headers = {};
  }
  if (typeof init.headers !== 'object') {
    throw new Error('headers must be an object');
  }
  // There are many ways to pass the headers but we always use an object
  // @ts-ignore
  init.headers['Authorization'] = `Bearer ${token}`;
  return fetch(input, init);
}

async function authFetchUsingCookie(
  input: RequestInfo | URL,
  init: RequestInit,
) {
  init.credentials = 'include';
  return fetch(input, init);
}

export async function authFetch(
  input: RequestInfo | URL,
  fetchWithoutValidRole = false,
  init?: RequestInit,
): Promise<Response> | undefined {
  let token: string;
  let parsedToken: JWTPayload;

  if (hasAuthCookie()) {
    const result = await fetchUserCheck();
    if (result.loggedin) {
      parsedToken = {
        email: result.email,
        roles: [USER_ROLE.subscribedUser],
      };
    }
  } else {
    token = await oidcClient.getActiveToken();
    parsedToken =
      token !== ''
        ? (oidcClient.parseJwtPayload(token) as unknown as JWTPayload)
        : undefined;
  }

  setUserStatus(parsedToken);

  const hasValidRole = Object.values(USER_ROLE).some((element) => {
    return parsedToken?.roles?.includes(element) || false;
  });

  if (!hasValidRole && !fetchWithoutValidRole) {
    return undefined;
  }

  if (!init) {
    init = {};
  }
  return hasAuthCookie()
    ? authFetchUsingCookie(input, init)
    : authFetchUsingToken(input, init, token);
}

export type TourType = 'normal' | 'recordedtrack';

// this is taken from the database
export const tourTimeCodes = [
  'wander',
  'velo',
  'mountain_biking',
  'skating',
  'canoeing',
  'winter_hiking',
  'snowshoe_trekking',
  'cross_country_skiing',
  'sledging',
  'no',
] as const;
export const tourTimeCodesWinter = [
  'winter_hiking',
  'snowshoe_trekking',
  'cross_country_skiing',
  'sledging',
  'wander',
  'velo',
  'mountain_biking',
  'skating',
  'canoeing',
  'no',
] as const;
export type TourTimeType = (typeof tourTimeCodes)[number];

export type TourColorName =
  | 'red'
  | 'orange'
  | 'yellow'
  | 'yellowgreen'
  | 'green'
  | 'darkgreen'
  | 'lightblue'
  | 'darkblue'
  | 'purple'
  | 'black';

export const TourColors: Record<TourColorName, string> = {
  red: '#e30613',
  orange: '#f39200',
  yellow: '#ffe500',
  yellowgreen: '#95c11f',
  green: '#009139',
  darkgreen: '#006633',
  lightblue: '#009fe3',
  darkblue: '#0065ae',
  purple: '#662483',
  black: '#1d1d1b',
};

export type TourPrefs = {
  color: TourColorName;
  colorRgb: string;
  opacity: number;
  gpspoints: number;
  timetype: TourTimeType;
  bikespeed?: number;
  hikespeed?: number;
  winterhikespeed?: number;
  snowshoespeed?: number;
  mountainbikespeed?: number;
};

export type TourCommonMeta = {
  elemin: number;
  elemax: number;
  length: number;
  totalup: number;
  totaldown: number;
};

export type TourMeta = TourCommonMeta & {
  biking?: number;
  walking?: number;
  snowshoeTrekking?: number;
  winterHiking?: number;
  mountainBiking?: number;
};

export type RecordedTourMeta = TourCommonMeta & {
  trackedTime: number;
  trackedTimePause: number;
  trackedTimeTotal: number;
  speedAverage: number;
  speedMax: number;
};

export type TourFilter = {
  id: number;
  name: string;
};

export type TourOwnProperties = {
  name: string;
  type: TourType;
  createdAt?: string;
  modifiedAt?: string;
  public: boolean;
  userdate?: string;
  isOwner?: string;
};

export type TourSaveProperties = {
  name?: string;
  public?: boolean;
  userdate?: string;
  pref?: TourPrefs;
};

/**
 * These properties are direct mapping from backend types (/api/6/tracks and /api/6/track, /api/6/recordedtrack).
 */
export type TourBaseProperties<T = TourMeta | RecordedTourMeta> = {
  meta: T;
  pref: TourPrefs;
  filter?: TourFilter;
} & TourOwnProperties;

export type DetailedTour = {
  id?: number;
  viapointsIndices: number[][];
  properties: TourBaseProperties<TourMeta>;
  geom: number[][][];
  profile?: number[][][];
  pois: InternalPOIDetails[];
};

export type DetailedRecordedTour = {
  id: number;
  geom: number[][][];
  recordedTimes?: number[][];
  properties: TourBaseProperties<RecordedTourMeta>;
  profile?: number[][][];
  pois: InternalPOIDetails[];
};

/**
 * See /api/6/tracks
 */
export type TourListItem = TourBaseProperties & {
  id: number;
};

export type InternalPOIDetails = {
  id?: number;
  title: string;
  description: string;
  photo: string;
  geometry: Coordinate;
  index: number;
  photoValidity?: 'valid' | 'temp';
};

type POIBackendProperties = {
  title: string;
  description: string;
  photo: string;
};

export type POIBackendFeatureCollection = FeatureCollection<
  GeoJSONPoint,
  POIBackendProperties
>;
export type POIBackendFeature = GeoJSONFeature<
  GeoJSONPoint,
  POIBackendProperties
>;

export type POIUpdate = {
  to_create: POIBackendFeatureCollection;
  to_update: POIBackendFeatureCollection;
  to_delete: number[];
};

export type PoiUpdateResponse = {
  created: number[];
  updated: number[];
  deleted: number[];
  deleted_photos: string[];
};

export type FiltersUpdate = {
  to_create: TourFilter[];
  to_update: TourFilter[];
  to_delete: number[];
};

export type FiltersUpdateResponse = {
  created: number[];
  updated: number[];
  deleted: number[];
};

export type TourDuplicateResponse = {
  id: number;
  name: string;
};

export type TourImportResponse = {
  id: number;
  name: string;
};

export type TourCopyResponse = {
  id: number;
  name: string;
  original_id: number;
  newly_imported: boolean;
  public: boolean;
};

export class HttpError extends Error {
  constructor(
    public status: number,
    message?: string,
  ) {
    super(message);
  }
}

export function getEmptyPOI(): InternalPOIDetails {
  return {
    title: '',
    description: '',
    id: undefined,
    photo: undefined,
    geometry: undefined,
    photoValidity: undefined,
    index: undefined,
  };
}

export async function fetchTours(): Promise<TourListItem[]> {
  const response: Response = await authFetch(`${BASE_API_URL}/api/6/tracks`);
  if (!response.ok) {
    throw new HttpError(
      response.status,
      `HTTP error! status: ${response.status}`,
    );
  }
  const data = (await response.json()).items;
  return data?.sort((a: TourListItem, b: TourListItem) =>
    a.name.localeCompare(b.name),
  );
}

export async function importTrackFromFile(
  file: File,
): Promise<TourImportResponse> {
  const formDF = new FormData();
  const filename = file.name.replace(/\.[^/.]+$/, '');
  formDF.append('trackfile', file);
  formDF.append('trackname', filename);
  const response: Response = await failNotOK(
    authFetch(`${BASE_API_URL}/api/6/import_track`, false, {
      method: 'POST',
      body: formDF,
    }),
  );
  if (!response.ok) {
    throw new HttpError(
      response.status,
      `HTTP error! status: ${response.status}`,
    );
  }
  return response.json();
}

export async function fetchTourDetails(id: number): Promise<DetailedTour> {
  const response: Response = await authFetch(
    `${BASE_API_URL}/api/6/tracks/${id}`,
    true,
  );
  if (!response.ok) {
    throw new HttpError(
      response.status,
      `HTTP error! status: ${response.status}`,
    );
  }
  const data = await response.json();
  const multiViapointsIndices = data.properties.viapointsIndices;
  delete data.properties.viapointsIndices;
  const multiCoordinates = data.geometry.coordinates;
  console.assert(Number.isFinite(multiCoordinates[0][0][0]));
  const detailedTour: DetailedTour = {
    id: data.id,
    properties: data.properties,
    geom: multiCoordinates,
    viapointsIndices: multiViapointsIndices,
    // FIXME: looks dangerous to initialize with no pois => if we save that the remote pois will be removed
    pois: undefined,
  };
  return detailedTour;
}

export async function fetchRecordedTourDetails(
  id: number,
): Promise<DetailedRecordedTour> {
  const response: Response = await failNotOK(
    authFetch(`${BASE_API_URL}/api/6/recordedtracks/${id}`, true),
  );
  if (!response.ok) {
    throw new HttpError(
      response.status,
      `HTTP error! status: ${response.status}`,
    );
  }
  const data = await response.json();
  const recordedTimes = data.properties.recordedTimes;
  delete data.properties.recordedTimes;
  const multiCoordinates = data.geometry.coordinates;
  console.assert(Number.isFinite(multiCoordinates[0][0][0]));
  const detailedRecordedTour: DetailedRecordedTour = {
    id: data.id,
    properties: data.properties,
    geom: multiCoordinates,
    pois: undefined,
    recordedTimes,
  };
  return detailedRecordedTour;
}

export function adaptPoiFeatureToDetails(
  poiFeatures: Feature[],
): InternalPOIDetails[] {
  return poiFeatures
    ? poiFeatures &&
        poiFeatures.map((f) => {
          const poi = f.getProperties() as InternalPOIDetails;
          poi.id = f.getId() as number;
          return poi;
        })
    : [];
}

export async function fetchTourPoiFeatures(
  id: number,
  type: TourType,
): Promise<POIBackendFeatureCollection> {
  const response: Response = await authFetch(
    `${BASE_API_URL}/api/${
      type === 'normal' ? '6/tracks' : '6/recordedtracks'
    }/${id}/pois`,
    true,
  );
  return await response.json();
}

type DetailsWithFeatureCollectionPOIs = {
  tour: DetailedRecordedTour | DetailedTour;
  pois: POIBackendFeatureCollection;
};

export async function fetchTourAndPois(
  id: number,
  type: TourType,
): Promise<DetailsWithFeatureCollectionPOIs> {
  let tour: DetailedRecordedTour | DetailedTour;
  let pois: POIBackendFeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };
  if (type === 'recordedtrack') {
    tour = await fetchRecordedTourDetails(id);
  } else {
    tour = await fetchTourDetails(id);
    pois = await fetchTourPoiFeatures(id, type);
  }
  return {tour, pois: pois};
}

export async function savePois(
  id: number,
  poisUpdate: POIUpdate,
): Promise<PoiUpdateResponse> {
  const response: Response = await failNotOK(
    authFetch(`${BASE_API_URL}/api/6/tracks/${id}/pois`, false, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(poisUpdate),
    }),
  );
  return response.json();
}

export async function saveTour(tour: DetailedTour): Promise<{id: number}> {
  if (!Number.isFinite(tour.viapointsIndices[0][0])) {
    throw new Error('viapoint ko');
  }
  const x = tour.geom[0][0][0];
  if (!(Number.isFinite(x) && x < 1000)) {
    // EPSG:4326
    throw new Error('track ko: ' + x);
  }
  if (tour.properties.name?.length < 1) {
    tour.properties.name = placeholderDateNow();
  }
  const body = JSON.stringify({
    type: 'Feature',
    id: tour.id,
    properties: Object.assign({}, tour.properties, {
      viapointsIndices: tour.viapointsIndices,
    }),
    geometry: {
      type: 'MultiLineString',
      coordinates: tour.geom,
    },
  });
  let method, url;
  if (tour.id) {
    method = 'PUT';
    url = `${BASE_API_URL}/api/6/tracks/${tour.id}`;
  } else {
    method = 'POST';
    url = `${BASE_API_URL}/api/6/tracks`;
  }
  const response: Response = await failNotOK(
    authFetch(url, false, {
      method,
      headers: {'Content-Type': 'application/json'},
      body: body,
    }),
  );
  return await response.json();
}

/**
 * Save the tour config to the usersettings
 */
export async function saveTourConfig(
  bikeSpeed: number,
  hikeSpeed: number,
  winterhikeSpeed: number,
  snowshoeSpeed: number,
  mountainbikeSpeed: number,
  color: TourColorName,
  gpsPoints: number,
  opacity: number,
  timeType: TourTimeType,
  filterId: number,
): Promise<void> {
  const method = 'PUT';
  const url = `${BASE_API_URL}/api/4/usersettings`;

  const body = JSON.stringify({
    bicycle_speed: bikeSpeed,
    hike_speed: hikeSpeed,
    winterhike_speed: winterhikeSpeed,
    snowshoe_speed: snowshoeSpeed,
    mountainbike_speed: mountainbikeSpeed,
    color: color,
    gps_points: gpsPoints,
    opacity: opacity,
    timetype: timeType,
    filterid: filterId,
  });

  await failNotOK(
    authFetch(url, false, {
      method,
      headers: {'Content-Type': 'application/json'},
      body: body,
    }),
  );
  return;
}

export async function fetchFilters(): Promise<TourFilter[]> {
  const url = `${BASE_API_URL}/api/6/filters`;
  const response: Response = await failNotOK(authFetch(url));
  return (await response.json()).items;
}

export async function saveOrUpdateFilters(
  filtersUpdate: FiltersUpdate,
): Promise<FiltersUpdateResponse> {
  const url = `${BASE_API_URL}/api/6/filters`;
  const response: Response = await failNotOK(
    authFetch(url, false, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(filtersUpdate),
    }),
  );
  return response.json();
}

export async function saveRecordedTourParts(
  id: number,
  properties: TourOwnProperties,
  pref: TourPrefs,
  filter: TourFilter,
): Promise<number> {
  // saving all the properties, including the ones that are not changed
  // for filter, we need to send null if it is not set; otherwise, the backend will not update it
  const body = JSON.stringify({
    ...properties,
    pref,
    filter: filter ? filter : null,
  });
  const url = `${BASE_API_URL}/api/6/recordedtracks/${id}`;
  const response: Response = await failNotOK(
    authFetch(url, false, {
      method: 'PATCH',
      headers: {'Content-Type': 'application/json'},
      body: body,
    }),
  );
  return response.json();
}

export async function deleteTour(
  id: number,
  tourType: TourType,
): Promise<void> {
  const tourRequestType = tourType === 'normal' ? 'tracks' : 'recordedtracks';
  const url = `${BASE_API_URL}/api/6/${tourRequestType}/${id}`;
  await failNotOK(
    authFetch(url, false, {
      method: 'DELETE',
    }),
  );
}

export async function duplicateTour(
  id: number,
  name: string,
): Promise<TourDuplicateResponse> {
  const url = `${BASE_API_URL}/api/6/tracks/${id}/duplicate`;
  const properties = {name, recordedtrack: false};
  const response: Response = await failNotOK(
    authFetch(url, false, {
      method: 'POST',
      body: JSON.stringify({properties}),
    }),
  );
  return response.json();
}

export async function duplicateRecordedTour(
  id: number,
  name: string,
): Promise<TourDuplicateResponse> {
  const url = `${BASE_API_URL}/api/6/to_normal_track`;
  const response: Response = await failNotOK(
    authFetch(url, false, {
      method: 'POST',
      body: JSON.stringify({id, name}),
    }),
  );
  return response.json();
}

export async function copyTour(
  id: number,
  tourType: TourType,
): Promise<TourCopyResponse> {
  const url = `${BASE_API_URL}/api/6/${tourType === 'normal' ? 'tracks' : 'recordedtracks'}/${id}/copy`;
  const response: Response = await failNotOK(
    authFetch(url, false, {
      method: 'PUT',
    }),
  );
  return response.json();
}

export async function saveTourParts(
  id: number,
  properties: TourSaveProperties,
  tourType: TourType,
): Promise<number> {
  const body = JSON.stringify({
    ...properties,
  });
  const url = `${BASE_API_URL}/api/6/${tourType === 'normal' ? 'tracks' : 'recordedtracks'}/${id}`;
  const response: Response = await failNotOK(
    authFetch(url, false, {
      method: 'PATCH',
      headers: {'Content-Type': 'application/json'},
      body: body,
    }),
  );
  return response.json();
}

export function failNotOK(r: Promise<Response>): Promise<Response> {
  return r.then((p) => {
    if (p.ok) {
      return r;
    }
    console.log('KO', p.status, p.statusText, p.url);
    throw new HttpError(p.status, p.statusText);
  });
}

export function compareFilters(a: TourFilter, b: TourFilter): number {
  return a.name.localeCompare(b.name);
}
