/**
 * Expose the env values in a standard way.
 * The actual implementation of the env replacement is hidden / application specific.
 */

if ('process' in globalThis) {
  (globalThis as any).envs = {
    BASE_API_URL: globalThis.process.env['BASE_API_URL'],
    WEBDB_S3_URL: globalThis.process.env['WEBDB_S3_URL'],
  };
}

if ('Deno' in globalThis) {
  // @ts-ignore
  const env = Deno.env.toObject();
  (globalThis as any).envs = {
    BASE_API_URL: env['BASE_API_URL'],
    WEBDB_S3_URL: env['WEBDB_S3_URL'],
  };
}

(globalThis as any).envs = (globalThis as any).envs ?? {};

export const {
  ROUTE_LOGOS_BASE_URL,
  POI_BASE_URL,
  GA_MEASUREMENT_ID,
  GRAPHHOPPER_URL,
  BASE_DATA_URL,
  BASE_API_URL,
  PRINT_URL,
  IMAGE_BASE_URL,
  CAPTCHA_SITEKEY,
  WEBDB_S3_URL,
  KEYCLOAK_ISSUER,
  VIADI_DEPARTURE_URL,
  VIADI_API_KEY,
  SCHWEIZMOBIL_PLUS_URL,
  SSE_URL,
} = (globalThis as any).envs;
